import React, {FormEvent, useEffect, useState} from "react";
import TagManager from "react-gtm-module";
import {subscribe} from "../../services/SubscriptionService";
import {Subscription} from "../../model/Subscription";
import {
    Input,
    Consent,
    Permission,
    Buttons, MOBILE_TELEPHONE_NUMBER_PATTERN, LANDLINE_TELEPHONE_NUMBER_PATTERN, chooseTelephoneNumberPattern
} from "@electrix/shared";
import {Html} from "./Html";
import {kebabCase} from "lodash";
import {MarketingChannel} from "../../model/MarketingChannel";
import {useSearchParams} from "react-router-dom";
import {getGclid} from "../../helpers/GclidUtils";


export interface IProps {
    page: string
    inputs: Input[]
    consents: Consent[]
    permissions: Permission[]
    buttons: Buttons
    marketingChannel: string
    offerId?: number
    onPreferenceChange: (value: string) => void
    subscription?: Subscription
    children?: JSX.Element | JSX.Element[]
    preview?: boolean
    permissionsIntro?: string
}

export const Inputs = (props: IProps) => {


    const [searchParams] = useSearchParams()
    const utmSource = searchParams.get('utm_source') || undefined
    const utmMedium = searchParams.get('utm_medium') || undefined
    const utmCampaign = searchParams.get('utm_campaign') || undefined
    const utmTerm = searchParams.get('utm_term') || undefined
    const utmContent = searchParams.get('utm_content') || undefined


    const [submitted, setSubmitted] = useState(false)
    const [email, setEmail] = useState<string>()
    const [title, setTitle] = useState<string>()
    const [firstName, setFirstName] = useState<string>()
    const [middleName, setMiddleName] = useState<string>()
    const [lastName, setLastName] = useState<string>()
    const [businessName, setBusinessName] = useState<string>()
    const [department, setDepartment] = useState<string>()
    const [mobileNumber, setMobileNumber] = useState<string>()
    const [phoneNumber, setPhoneNumber] = useState<string>()
    const [contactNumber, setContactNumber] = useState<string>()
    const [numberOfEmployees, setNumberOfEmployees] = useState<number>()
    const [annualMileage, setAnnualMileage] = useState<number>()
    const [jobTitle, setJobTitle] = useState<string>()
    const [tradingMoreThan2Years, setTradingMoreThan2Years] = useState<boolean>();
    const [isDisabled, setIsDisabled] = useState(false)


    const [newsletters, setNewsletters] = useState<boolean>();
    const [specialOffers, setSpecialOffers] = useState<boolean>();
    const [events, setEvents] = useState<boolean>();


    useEffect(() => {
        if (props.subscription) {
            setEmail(props.subscription.email)
            setTitle(props.subscription.title)
            setFirstName(props.subscription.firstName)
            setLastName(props.subscription.lastName)
            setMiddleName(props.subscription.middleName)

            setBusinessName(props.subscription.businessName)
            setDepartment(props.subscription.department)
            setMobileNumber(props.subscription.mobileNumber)
            setPhoneNumber(props.subscription.phoneNumber)
            if (props.subscription.phoneNumber) {
                setContactNumber(props.subscription.phoneNumber)
            }
            if (props.subscription.mobileNumber) {
                setContactNumber(props.subscription.mobileNumber)
            }
            setNumberOfEmployees(props.subscription.numberOfEmployees)
            setAnnualMileage(props.subscription.annualMileage)
            setJobTitle(props.subscription.jobTitle)
            props.inputs.some(input => input.type === "TradingMoreThan2Years") && setTradingMoreThan2Years(props.subscription.tradingMoreThan2Years || false)
            props.permissions.some(permission => permission.type === "Newsletters") && setNewsletters(props.subscription.newsletters || false)
            props.permissions.some(permission => permission.type === "SpecialOffers") && setSpecialOffers(props.subscription.specialOffers || false)
            props.permissions.some(permission => permission.type === "Events") && setEvents(props.subscription.events || false)
        }

    }, [props.subscription]);

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (props.preview) {
            alert("This is preview page!")
            return
        }
        setSubmitted(true)
        // @ts-ignore
        for (let i = 0; i < event.target.length; i++) {
            // @ts-ignore
            const it = (event.target[i] as HTMLInputElement);
            if (it.validity && !it.validity.valid) {
                return;
            }
        }
        setIsDisabled(true)

        let optForNewsLetters
        let optForSpecialOffers
        let optForEvents

        const hasPermissionWithTypeNewsletters = props.permissions.some(permission => permission.type === "Newsletters");
        const hasPermissionWithTypeSpecialOffers = props.permissions.some(permission => permission.type === "SpecialOffers");
        const hasPermissionWithTypeEvents = props.permissions.some(permission => permission.type === "Events");

        if ([MarketingChannel.TCO, MarketingChannel.ULTIMATE_GUIDE, MarketingChannel.SALARY_SACRIFICE].some(x => x === props.marketingChannel)) {
            optForNewsLetters = hasPermissionWithTypeNewsletters ? newsletters : true
            optForSpecialOffers = hasPermissionWithTypeSpecialOffers ? specialOffers : true
            optForEvents = hasPermissionWithTypeEvents ? events : true
        } else {
            optForNewsLetters = hasPermissionWithTypeNewsletters ? newsletters : false
            optForSpecialOffers = hasPermissionWithTypeSpecialOffers ? specialOffers : false
            optForEvents = hasPermissionWithTypeEvents ? events : false
        }

        subscribe({
            utmSource,
            utmMedium,
            utmCampaign,
            utmTerm,
            utmContent,
            gclid: getGclid(),
            businessName: businessName,
            department: department,
            title: title,
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            mobileNumber: contactNumber?.startsWith("07") ? contactNumber : mobileNumber,
            phoneNumber: (contactNumber?.startsWith("01") || contactNumber?.startsWith("02")) ? contactNumber : phoneNumber,
            numberOfEmployees: numberOfEmployees,
            annualMileage: annualMileage,
            jobTitle: jobTitle,
            email: email,
            page: props.page,
            tradingMoreThan2Years: tradingMoreThan2Years,
            marketingChannel: props.marketingChannel,
            newsletters: optForNewsLetters,
            specialOffers: optForSpecialOffers,
            events: optForEvents,
            linkedOfferId: props.offerId
        }).then(() => {
                props.onPreferenceChange('EMAIL')
                window.scrollTo(0, 0)
                setIsDisabled(false)
                signupDataLayer()
            }
        )
    }

    const signupDataLayer = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'signmeup',
                first_name: firstName,
                last_name: lastName,
                phone_number: phoneNumber,
                marketing_channel: props.marketingChannel,
                email_address: email
            }
        })
    }

    const cancelClick = () => {
        if (props.preview) {
            alert("This is preview page!")
            return
        }
        if (props.buttons.cancel?.navigateTo) {
            window.location.href = props.buttons.cancel?.navigateTo
        }
    }

    return (
        <form className={`subscription-box__form ${submitted && "submitted"}`} onSubmit={onSubmit} noValidate={true}>
            <h1>Fill in the form<br/> and we'll do the rest.</h1>
            {props.inputs.map(input => {
                return <div className='mb-4 preferences-input' key={input.type}>
                    {input.type === "Salutation" && (
                        <>
                            <label className="form-label" htmlFor="title">Title
                                {input.required ? <span> *</span> : ''}
                            </label>
                            <select value={title}
                                    id="title"
                                    className="form-control rounded-pill"
                                    required={input.required}
                                    onChange={e => setTitle(e.target.value)}>
                                <option value="">{input.placeholder ?? 'Please select...'}</option>
                                <option className="mr" value="MR">Mr</option>
                                <option className="mrs" value="MRS">Mrs</option>
                                <option className="miss" value="MISS">Miss</option>
                                <option className="ms" value="MS">Ms</option>
                                <option className="doctor" value="DR">Doctor</option>
                            </select>
                            <div className="invalid-feedback">
                                <img src="/account-img/error.png"/>
                                Enter your Title
                            </div>
                        </>
                    )}
                    {input.type === "FirstName" && (
                        <>
                            <label className="form-label" htmlFor="first-name">First name
                                {input.required ? <span> *</span> : ''}
                            </label>
                            <input className="form-control rounded-pill"
                                   type='text'
                                   id="first-name"
                                   name="first-name"
                                   value={firstName}
                                   onChange={e => setFirstName(e.target.value)}
                                   maxLength={255}
                                   required={input.required}
                                   placeholder={input.placeholder ?? ''}
                            />
                            <div className="invalid-feedback">
                                <img src="/account-img/error.png"/>
                                Enter your First name
                            </div>
                        </>
                    )}
                    {input.type === "MiddleName" && (
                        <>
                            <label className="form-label" htmlFor="middle-name">Middle name
                                {input.required ? <span> *</span> : ''}
                            </label>
                            <input className="form-control rounded-pill"
                                   type='text'
                                   id="middle-name"
                                   name="middle-name"
                                   value={middleName}
                                   onChange={e => setMiddleName(e.target.value)}
                                   maxLength={255}
                                   required={input.required}
                                   placeholder={input.placeholder ?? ''}
                            />
                            <div className="invalid-feedback">
                                <img src="/account-img/error.png"/>
                                Enter your Middle Name
                            </div>
                        </>
                    )}
                    {input.type === "LastName" && (
                        <>
                            <label className="form-label" htmlFor="last-name">Last name
                                {input.required ? <span> *</span> : ''}
                            </label>
                            <input className="form-control rounded-pill"
                                   type='text'
                                   id="last-name"
                                   name="last-name"
                                   value={lastName}
                                   onChange={e => setLastName(e.target.value)}
                                   maxLength={255}
                                   required={input.required}
                                   placeholder={input.placeholder ?? ''}
                            />
                            <div className="invalid-feedback">
                                <img src="/account-img/error.png"/>
                                Enter your Last name
                            </div>
                        </>
                    )}
                    {input.type === "EmailAddress" && (
                        <>
                            <label className="form-label" htmlFor="email-address">Email address
                                {input.required ? <span> *</span> : ''}
                            </label>
                            <input className="form-control rounded-pill"
                                   type='email'
                                   id="email-address"
                                   name="email-address"
                                   value={email}
                                   onChange={e => setEmail(e.target.value)}
                                   maxLength={255}
                                   required={input.required}
                                   placeholder={input.placeholder ?? ''}
                            />
                            <div className="invalid-feedback">
                                <img src="/account-img/error.png"/>
                                Enter a valid email address
                            </div>
                        </>
                    )}
                    {input.type === "BusinessName" && (
                        <>
                            <label className="form-label" htmlFor="business-name">Business name
                                {input.required ? <span> *</span> : ''}
                            </label>
                            <input className="form-control rounded-pill" type='text'
                                   name="business-name"
                                   id="business-name"
                                   value={businessName}
                                   onChange={e => setBusinessName(e.target.value)}
                                   maxLength={255}
                                   required={input.required}
                                   placeholder={input.placeholder ?? ''}
                            />
                            <div className="invalid-feedback">
                                <img src="/account-img/error.png"/>
                                Enter your Business name
                            </div>
                        </>
                    )}
                    {input.type === "Department" && (
                        <>
                            <label className="form-label" htmlFor="department">Department
                                {input.required ? <span> *</span> : ''}
                            </label>
                            <input className="form-control rounded-pill" type='text'
                                   name="department"
                                   id="department"
                                   value={department}
                                   onChange={e => setDepartment(e.target.value)}
                                   maxLength={255}
                                   required={input.required}
                                   placeholder={input.placeholder ?? ''}
                            />
                            <div className="invalid-feedback">
                                <img src="/account-img/error.png"/>
                                Enter your Department
                            </div>
                        </>
                    )}
                    {input.type === "JobTitle" && (
                        <>
                            <label className="form-label" htmlFor="job-title">Job Title
                                {input.required ? <span> *</span> : ''}
                            </label>
                            <input className="form-control rounded-pill" type='text'
                                   name="job-title"
                                   id="job-title"
                                   value={jobTitle}
                                   onChange={e => setJobTitle(e.target.value)}
                                   maxLength={255}
                                   required={input.required}
                                   placeholder={input.placeholder ?? ''}
                            />
                            <div className="invalid-feedback">
                                <img src="/account-img/error.png"/>
                                Enter your Job title
                            </div>
                        </>
                    )}
                    {input.type === "MobileNumber" && (
                        <>
                            <label className="form-label" htmlFor="mobile-number">Mobile number
                                {input.required ? <span> *</span> : ''}
                            </label>
                            <input className="form-control rounded-pill"
                                   pattern={MOBILE_TELEPHONE_NUMBER_PATTERN}
                                   name="mobile-number"
                                   id="mobile-number"
                                   value={mobileNumber}
                                   onChange={e => setMobileNumber(e.target.value)}
                                   maxLength={255}
                                   required={input.required}
                                   placeholder={input.placeholder ?? '07XXXXXXX'}
                            />
                            <div className="invalid-feedback">
                                <img src="/account-img/error.png"/>
                                Enter your Contact number
                            </div>
                        </>
                    )}
                    {input.type === "PhoneNumber" && (
                        <>
                            <label className="form-label" htmlFor="phone-number">Phone number
                                {input.required ? <span> *</span> : ''}
                            </label>
                            <input className="form-control rounded-pill"
                                   pattern={LANDLINE_TELEPHONE_NUMBER_PATTERN}
                                   name="phone-number"
                                   id="phone-number"
                                   value={phoneNumber}
                                   onChange={e => setPhoneNumber(e.target.value)}
                                   maxLength={255}
                                   required={input.required}
                                   placeholder={input.placeholder ?? '01/02XXXXXXX'}
                            />
                            <div className="invalid-feedback">
                                <img src="/account-img/error.png"/>
                                Enter your Phone number
                            </div>
                        </>
                    )}
                    {input.type === "ContactNumber" && (
                        <>
                            <label className="form-label" htmlFor="contact-number">Contact number
                                {input.required ? <span> *</span> : ''}
                            </label>
                            <input className="form-control rounded-pill"
                                   pattern={chooseTelephoneNumberPattern(contactNumber)}
                                   name="contact-number"
                                   id="contact-number"
                                   value={contactNumber}
                                   onChange={e => setContactNumber(e.target.value)}
                                   maxLength={255}
                                   required={input.required}
                                   placeholder={input.placeholder ?? '01/02/07XXXXXXX'}
                            />
                            <div className="invalid-feedback">
                                <img src="/account-img/error.png"/>
                                Enter your Contact number
                            </div>
                        </>
                    )}
                    {input.type === "NumberOfEmployees" && (
                        <>
                            <label className="form-label" htmlFor="number-of-employees">Number of employees
                                {input.required ? <span> *</span> : ''}
                            </label>
                            <input className="form-control rounded-pill"
                                   type="number"
                                   min={1}
                                   name="number-of-employees"
                                   id="number-of-employees"
                                   value={numberOfEmployees}
                                   onChange={e => setNumberOfEmployees(e.target.valueAsNumber)}
                                   required={input.required}
                                   placeholder={input.placeholder ?? ''}
                            />
                            <div className="invalid-feedback">
                                <img src="/account-img/error.png"/>
                                Enter Number of employees
                            </div>
                        </>
                    )}
                    {input.type === "AnnualMileage" && (
                        <>
                            <label className="form-label" htmlFor="annual-mileage">Annual mileage
                                {input.required ? <span> *</span> : ''}
                            </label>
                            <input className="form-control rounded-pill"
                                   type="number"
                                   name="annual-mileage"
                                   id="annual-mileage"
                                   value={annualMileage}
                                   onChange={e => setAnnualMileage(e.target.valueAsNumber)}
                                   min={3000}
                                   max={40000}
                                   required={input.required}
                                   placeholder={input.placeholder ?? ''}
                            />
                            <div className="invalid-feedback">
                                <img src="/account-img/error.png"/>
                                Enter your Annual mileage
                            </div>
                        </>
                    )}
                    {input.type === "TradingMoreThan2Years" && (
                        <div className='form-check'>
                            <input className='form-check-input'
                                   id="trading-more-than-2-years"
                                   type="checkbox"
                                   checked={tradingMoreThan2Years}
                                   onChange={e => setTradingMoreThan2Years(e.target.checked)}
                                   name="trading-more-than-2-years"/>

                            <label className='form-check-label' htmlFor="trading-more-than-2-years">
                                We have been trading for more than 2 years</label>
                        </div>
                    )}
                </div>
            })}

            {props.consents.map(consent => {
                return <div className='form-check mb-3 preferences-input' key={kebabCase(consent.definition)}>
                    <input className='form-check-input'
                           id={`consent-${kebabCase(consent.definition)}`}
                           type="checkbox"
                           name={`consent-${kebabCase(consent.definition)}`}
                           required/>
                    <label className='form-check-label preferences-consent-label'
                           htmlFor={`consent-${kebabCase(consent.definition)}`}><Html
                        html={consent.label || ""}/></label>
                    <div className="invalid-feedback">
                        <img src="/account-img/error.png"/>
                        Please accept
                    </div>
                </div>
            })}
            <div className="preferences-permissions-intro">
                <Html html={props.permissionsIntro || ""}/>
            </div>

            {props.permissions.map(permission => {
                return <div className='form-check mb-3' key={kebabCase(permission.type)}>
                    {permission.type === "Newsletters" && (
                        <input className='form-check-input'
                               id={`permission-${kebabCase(permission.type)}`}
                               checked={newsletters}
                               type="checkbox"
                               onChange={e => setNewsletters(e.target.checked)}
                               name={`permission-${kebabCase(permission.type)}`}/>
                    )}
                    {permission.type === "SpecialOffers" && (
                        <input className='form-check-input'
                               id={`permission-${kebabCase(permission.type)}`}
                               checked={specialOffers}
                               type="checkbox"
                               onChange={e => setSpecialOffers(e.target.checked)}
                               name={`permission-${kebabCase(permission.type)}`}/>
                    )}
                    {permission.type === "Events" && (
                        <input className='form-check-input'
                               id={`permission-${kebabCase(permission.type)}`}
                               checked={events}
                               type="checkbox"
                               onChange={e => setEvents(e.target.checked)}
                               name={`permission-${kebabCase(permission.type)}`}/>
                    )}
                    <label className='form-check-label preferences-consent-label'
                           htmlFor={`permission-${kebabCase(permission.type)}`}><Html
                        html={permission.label || ""}/></label>
                </div>
            })}

            <div className='text-center text-lg-start'>


                {props.buttons && props.buttons.submit && (
                    <input className='cta cta--green me-1 w-100'
                           type='submit'
                           value={props.buttons.submit.label || "Submit"}
                           name={'submit-form'}
                           disabled={isDisabled}
                    />
                )}
                {props.buttons && props.buttons.cancel && (
                    <input className='cta cta--blue-outline w-100'
                           onClick={cancelClick}
                           type='button'
                           value={props.buttons.cancel.label || "Cancel"}
                           name={'cancel-form'}/>
                )}
            </div>
        </form>
    )
}
