import {AlertColor} from "@mui/material";
import {Level} from "../model/ProblemDetail";

export const mapToAlertColor = (level: Level) : AlertColor => {
    if (Level.ERROR.toLowerCase() === level.toLowerCase()) {
        return 'error'
    }

    if (Level.WARNING.toLowerCase() === level.toLowerCase()) {
        return 'warning'
    }

    if (Level.INFO.toLowerCase() === level.toLowerCase()) {
        return 'info'
    }

    return 'success'
}