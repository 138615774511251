import React, {useEffect, useState} from 'react'
import {MarketingChannel} from "../../model/MarketingChannel";
import {useAppDispatch, useAppSelector} from "../../store/Hooks";
import {fetchOffersThunk, selectFetchOffersStatus, selectOfferByLinkedOfferId} from "../../slices/OfferSlice";
import {ThunkStatus} from "../../store/State";
import {useParams} from "react-router-dom";
import {fetchSubscription} from "../../services/SubscriptionService";
import {Subscription} from "../../model/Subscription";
import Footer from "./Footer";
import {Page} from "@electrix/shared";
import {Html} from "./Html";
import {Inputs} from "./Inputs";
import Header from "./Header";

interface IProps {
    onPreferenceChange: (value: string) => void
    marketingChannel: string
    linkedOfferId?: number
    pageConfig: Page
    preview?: boolean
    page: string
}

export const UpdatePreferences = (props: IProps) => {

    const [greeting, setGreeting] = useState(props.pageConfig.greeting)

    const [subscription, setSubscription] = useState<Subscription | undefined>()
    const isMarketingChannelTCO = props.marketingChannel === MarketingChannel.TCO

    const dispatch = useAppDispatch()
    const offersStatus = useAppSelector(state => selectFetchOffersStatus(state))
    const offer = useAppSelector(state => selectOfferByLinkedOfferId(state, props.linkedOfferId))

    const make = offer ? offer.evOffer.vehicle.manufacturer : ""
    const model = offer ? offer.evOffer.vehicle.range : ""

    const identity = useParams().identity as string

    useEffect(() => {
        if (offersStatus === ThunkStatus.idle && isMarketingChannelTCO) {
            dispatch(fetchOffersThunk())
        }
        fetchSubscription(
            identity
        ).then(s => {
            setSubscription(s)
            setGreeting(props.pageConfig.greetingPersonalized ?
                greeting.replace("<h1>", `<h1>Welcome back ${s.firstName + " " + s.lastName}, `) :
                greeting)
        })
    }, [identity, isMarketingChannelTCO, offersStatus, dispatch]);

    useEffect(() => {
             setGreeting(subscription && props.pageConfig.greetingPersonalized ?
                greeting.replace("<h1>", `<h1>Welcome back ${subscription.firstName + " " + subscription.lastName}, `) :
                 props.pageConfig.greeting)
    }, [props.pageConfig]);

    const context = {
        ...subscription,
        make,
        model
    }

    return (
        <>
            <Header/>
            <header className='bg-electrix-gradient pb-5'>
                <div className="container-lg">
                    <div
                        className='row d-flex flex-column-reverse justify-content-between px-4 pt-5 pb-4 pb-lg-5 flex-lg-row px-lg-1'>
                        <div className="mt-5 col-lg-4 col-xl-5 pe-0 preferences-greeting">
                            <Html html={greeting} context={context}/>
                        </div>
                        <div className="d-flex justify-content-center justify-content-lg-end col-lg-8 col-xl-7">
                            {props.pageConfig.greetingImage && (
                                <img src={props.pageConfig.greetingImage.content} className="w-100 car-gallery-page-hero" alt='Electric Vehicle'/>
                            )}
                        </div>
                    </div>
                </div>
            </header>
            <section className=''>
                <div className='container-lg'>
                    <div className='subscription-container row'>
                        <div className="subscription-info col-lg-6 col-xl-8 preferences-body">
                            <Html html={props.pageConfig.body} context={context}/>
                        </div>
                        <div className='subscription-box col-xl-4 col-lg-6'>

                            <Inputs page={props.page}
                                    permissionsIntro={props.pageConfig.permissionsIntro}
                                    inputs={props.pageConfig.inputs}
                                    consents={props.pageConfig.consents}
                                    permissions={props.pageConfig.permissions}
                                    buttons={props.pageConfig.buttons}
                                    marketingChannel={props.marketingChannel}
                                    subscription={subscription}
                                    offerId={props.linkedOfferId}
                                    preview={props.preview}
                                    onPreferenceChange={props.onPreferenceChange}/>
                        </div>
                    </div>
                    <hr className="bg-grey mt-4 "/>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default UpdatePreferences;
