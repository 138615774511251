/**
 * Taken from https://support.google.com/google-ads/answer/7012522?hl=en-GB and modified
 */


function getParam(paramName : string) {
    const match = RegExp('[?&]' + paramName + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

function getExpiryRecord(value: string) {
    const expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds

    const expiryDate = new Date().getTime() + expiryPeriod;
    return {
        value: value,
        expiryDate: expiryDate
    };
}

export function getGclid() {
    const gclidParam = getParam('gclid');
    let gclidRecord = null;

    const gclSrcParam = getParam('gclsrc');
    const isGclSrcValid = !gclSrcParam || gclSrcParam.indexOf('aw') !== -1;


    if (gclidParam && isGclSrcValid) {
        gclidRecord = getExpiryRecord(gclidParam);
        localStorage.setItem('gclid', JSON.stringify(gclidRecord));
    }


    const storedGclid = localStorage.getItem('gclid')

    if(storedGclid) {
        const gclid = gclidRecord || JSON.parse(storedGclid);
        const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate;

        if (isGclidValid) {
            return gclid.value;
        }
    }
}

