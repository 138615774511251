import React, {useEffect, useRef} from "react";
import Footer from "./Footer";
import NavBar from "./tco/NavBar";
import {Link, useParams} from "react-router-dom";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../store/Hooks";
import {
  fetchOffersThunk,
  selectAllOffers,
  selectFetchOffersStatus,
  selectFetchOfferStatus,
  selectOfferByLinkedOfferId
} from "../slices/OfferSlice";
import {ThunkStatus} from "../store/State";
import {MarketingChannel} from "../model/MarketingChannel";
import TagManager from "react-gtm-module";
import {useAddOrEditMetaTagWithAttributeValues, useEditMetaTagContentByName, useTitle} from "../helpers/PageHeadHandler";
import {getVehicleImageSrc} from "../helpers/Helper";

export const Comparison = () => {
  const id = useParams().id as string
  const linkedOfferId = parseInt(id)
  const dispatch = useAppDispatch()
  const offersStatus = useAppSelector(state => selectFetchOffersStatus(state))
  const offers = useAppSelector(state => selectAllOffers(state))
  const offerStatus = useAppSelector(state => selectFetchOfferStatus(state, linkedOfferId))
  const offer = useAppSelector(state => selectOfferByLinkedOfferId(state, linkedOfferId))

  useTitle('Comparison Page')
  useEditMetaTagContentByName('description', 'Compare an ' + offer?.evOffer.vehicle.manufacturer +
      " " + offer?.evOffer.vehicle.model + " to " + offer?.iceOffer.vehicle.manufacturer + ' ' + offer?.iceOffer.vehicle.model)
  useAddOrEditMetaTagWithAttributeValues("og:title", "Compare an " + offer?.evOffer.vehicle.manufacturer +
      " " + offer?.evOffer.vehicle.model + " electric car | ElectriX")
  useAddOrEditMetaTagWithAttributeValues("og:url", window.location.href)

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (offer !== undefined) {
      if (isInitialMount.current) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'TCO - compare pageview',
            make_TCO: offer?.evOffer.vehicle.manufacturer,
            model_TCO: offer?.evOffer.vehicle.model,
            monthly_rental_TCO: offer?.evOffer.leaseMonthlyCost,
            monthly_insurance_TCO: offer?.evOffer.insuranceMonthlyCost
          }
        })

        isInitialMount.current = false
      }
    }

    window.scrollTo(0, 0)
  })

  useEffect(() => {
    if (offersStatus === ThunkStatus.idle && offerStatus === ThunkStatus.idle) {
      dispatch(fetchOffersThunk())
    }
  }, [offersStatus, dispatch, offer, offerStatus])

  const evOffer = offer?.evOffer
  const iceOffer = offer?.iceOffer
  const evModel = evOffer?.vehicle !== undefined ? evOffer.vehicle.model : ''
  const evManufacturer = evOffer?.vehicle !== undefined ? evOffer.vehicle.manufacturer : ''
  const singleCarResultUrl = '/single-car-result/' + id;

  let currentMonth = new Date().getMonth() + 1
  let currentYear = new Date().getFullYear()

  return (
    <>
      <header>
        <NavBar/>
      </header>
      <section className="d-flex flex-column px-0 container-lg">
        <div className="d-flex justify-content-around bd-highlight mb-3 pt-4 mb-md-4">
          <div className="p-4 pt-0 col col-md-4 text-center card-width comparison-card-border">
            <p className="fs-8 fs-sm-8 fw-bold ff-mulish vehicle-manufacturer-fs">&nbsp;</p>
            <p className="fs-sm-7 ff-livvic pt-0 min-height-60 fw-bold text-electrix-ocean-blue vehicle-manufacturer-fs">
              {evManufacturer} {evModel}
            </p>
            {evOffer?.vehicle && <img src={getVehicleImageSrc(evOffer?.vehicle)} className="vehicle-image-size img-fluid img-form" alt="Electric Vehicle"/>}

            <p className="d-none d-sm-block mb-3 min-height-60 ff-mulish fw-bold text-black">
              {evOffer?.vehicle.derivative}
            </p>
            <div className="pt-2 pt-lg-0 pt-md-0">
              <img src='/account-img/icons/electric-sign.svg' alt='Electric'/>
              <p className="mb-0 fs-sm-7 ff-livvic fw-bold text-electrix-blue">ELECTRIC</p>
            </div>
          </div>
          <div className="p-4 pt-0 col col-md-4 text-center card-width comparison-card-border">
            <div className="position-relative d-flex justify-content-center">
              <p className="fs-8 fs-sm-8 fw-bold ff-mulish text-electrix-blue-light vehicle-manufacturer-fs vehicle-manufacturer-fs-xs">COMPARISON CAR</p>
              <OverlayTrigger
                  trigger="focus"
                  placement="bottom"
                  overlay={
                    <Popover id="carLeasePopover">
                      <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                      <Popover.Body>
                        Where possible we have used the nearest petrol or diesel equivalent model for comparison. If
                        there is no direct equivalent we will look at multiple characteristics of the electric vehicle
                        and map these accordingly to determine a suitable comparative vehicle.
                      </Popover.Body>
                    </Popover>
                  }
              >
                <Button className="position-absolute mt-2 end-0 info-icon" variant="link"
                        onClick={(e) => e.currentTarget.focus()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 122.8 123.3">
                    <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10" strokeMiterlimit="10" cx="61.4" cy="61.6"
                             rx="56.4" ry="56.6"/>
                    <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                    <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
            <p className="fs-sm-7 ff-livvic fw-bold pt-0 min-height-60 text-electrix-ocean-blue vehicle-manufacturer-fs">
              {iceOffer?.vehicle.manufacturer} {iceOffer?.vehicle.model}
            </p>
            {iceOffer?.vehicle && <img src={getVehicleImageSrc(iceOffer?.vehicle)} className="vehicle-image-size img-fluid img-form" alt="Comparison Vehicle"/>}

            <p className="d-none d-sm-block mb-3 min-height-60 ff-mulish fw-bold text-black">
              {iceOffer?.vehicle.derivative}
            </p>
            <div className="pt-2 pt-lg-0 pt-md-0">
              <img src='/account-img/icons/gas-pump-sign.svg' alt='Gas Pump'/>
              <p className="mb-0 fs-sm-7 ff-livvic fw-bold text-electrix-blue">{iceOffer?.vehicle.fuelType?.replaceAll('_', ' ')}</p>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div
            className="d-flex flex-row justify-content-center px-lg-4 pt-4 pt-lg-0 pt-md-0 pb-lg-4 gradient-box-border-margin gradient-box-border-padding">
            <div className="gradient-box-border-wrap col-12">
              <div className="gradient-box bg-white">
                <div
                  className="d-flex flex-row justify-content-center inside-gradient-row inside-gradient-row-lg">
                  <div className="col-9 text-center">
                    <h4 className="comparison ff-livvic">Estimated total monthly costs</h4>
                  </div>
                </div>
                <div className="d-flex flex-row inside-gradient-row inside-gradient-row-lg text-center">
                  <div className="col-6 comparison-border-right">
                    <img src='/account-img/icons/like.svg'
                         className={'icon-padding-bottom ' + (evOffer?.estimatedMonthlyCost! < iceOffer?.estimatedMonthlyCost! ? '' : 'd-none')}
                         alt='Like'/>
                  </div>
                  <div className="col-6 comparison-border-left">
                    <img src='/account-img/icons/like.svg'
                         className={"icon-padding-bottom " + (iceOffer?.estimatedMonthlyCost! < evOffer?.estimatedMonthlyCost! ? '' : 'd-none')}
                         alt='Like'/>
                  </div>
                </div>
                <div className="d-flex flex-row inside-gradient-row inside-gradient-row-lg text-center">
                  <div className="col-6 comparison-border-right text-center"><h2
                    className="comparison text-center ff-livvic">&#163;{evOffer?.estimatedMonthlyCost}</h2></div>
                  <div className="col-6 comparison-border-left text-center"><h2
                    className="comparison text-center ff-livvic">&#163;{iceOffer?.estimatedMonthlyCost}</h2></div>
                </div>
                <div className="d-flex flex-row justify-content-center">
                  <div className="col-11 text-center">
                    <p className="costs-calculation-info text-center ff-mulish">Costs are
                      calculated on leasing
                      for {offers.term} months, and driving {offers.mileage.toLocaleString()} miles per year.</p>
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-center">
                  <div className="col-12 text-center px-md-4">
                    <p className="bg-cool-grey p-2 ff-mulish">
                      <strong>Costs shown are indicative based on market averages and may differ
                        based on your personal circumstances.
                      </strong>
                    </p>
                  </div>
                </div>

                <div className="d-none d-md-block px-4 pb-2">
                  <div id="viewTheseCostsInDetailTablet">
                    <h2 id="viewCalculationInDetailTabletHeading">
                      <button className="collapse-button collapsed" type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#viewCalculationInDetailTabletText"
                              aria-expanded="false"
                              aria-controls="viewCalculationInDetailTabletText">
                                                            <span
                                                              className="fw-bold ps-3">How do we calculate these costs?</span>
                      </button>
                    </h2>
                    <div id="viewCalculationInDetailTabletText" className="bg-cool-grey collapse"
                         aria-labelledby="viewCalculationInDetailTabletHeading"
                         data-bs-parent="#viewCalculationInDetailTabletHeading">
                      <div className="px-3 py-1">
                        <p className="collapse-button__content">
                          <span className="fw-bold ff-livvic">Car lease</span><br/>
                          This price doesn’t include the Initial Payment of
                          &#163;{evOffer?.initialRentalCost}, and a Processing Fee
                          of &#163;{evOffer?.contractFee}.
                          All prices shown subject to change at any time and would
                          be
                          subject to finance approval and vehicle availability.
                          Failure to maintain payments may result in termination
                          of
                          your agreement and the vehicle being returned, this
                          could effect your credit rating and make it more
                          difficult
                          to obtain credit in the future. All prices correct
                          at time of publication and maybe withdrawn without prior
                          notice. Personal Lease prices include VAT.
                          When pursing a formal quote, you should try and estimate
                          the
                          distance you will travel as accurately as
                          possible to try and avoid excess mileage charges. Excess
                          mileage charge will apply and will be detailed
                          in any formal quotation. Damage charges may apply.
                          <br/><br/>
                          <span className="fw-bold ff-livvic">Insurance</span><br/>
                          Estimations are based on data provided by Allianz Insurance using their recent quote and sales information for this vehicle.<br/><br/>
                          In order to generate this indicative price the following parameters were used. A minimum sample size of at least 100 quotations or sales, generated over the 3 months of Jan 2023 to March 2023 and spanning a variety of insurable risks. Optional Add-ons were not included.<br/><br/>
                          NB: Insurance is based on individual circumstances and any prices or eligibility are specific to each driver.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-md-none px-0">
                  <div id="viewTheseCostsInDetailMobile">
                    <h2 id="viewTheseCostsInDetailMobileHeading">
                      <button className="px-4 collapse-button collapsed" type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#viewTheseCostsInDetailMobileText"
                              aria-expanded="false"
                              aria-controls="viewTheseCostsInDetailMobileText">
                        <span className="fw-bold">How do we calculate these costs?</span>
                      </button>
                    </h2>
                    <div id="viewTheseCostsInDetailMobileText" className="bg-cool-grey collapse"
                         aria-labelledby="viewTheseCostsInDetailMobileHeading" data-bs-parent="#viewTheseCostsInDetailMobile">
                      <p className="px-4 collapse-button__content">
                        <span className="fw-bold ff-livvic">Car lease</span><br/>
                        This price doesn’t include the Initial Payment of
                        &#163;{evOffer?.initialRentalCost}, and a Processing Fee
                        of &#163;{evOffer?.contractFee}.
                        All prices shown subject to change at any time and would
                        be
                        subject to finance approval and vehicle availability.
                        Failure to maintain payments may result in termination
                        of
                        your agreement and the vehicle being returned, this
                        could effect your credit rating and make it more
                        difficult
                        to obtain credit in the future. All prices correct
                        at time of publication and maybe withdrawn without prior
                        notice. Personal Lease prices include VAT.
                        When pursing a formal quote, you should try and estimate
                        the
                        distance you will travel as accurately as
                        possible to try and avoid excess mileage charges. Excess
                        mileage charge will apply and will be detailed
                        in any formal quotation. Damage charges may apply.
                        <br/><br/>
                        <span className="fw-bold ff-livvic">Insurance</span><br/>
                        Estimations are based on data provided by Allianz Insurance using their recent quote and sales information for this vehicle.<br/><br/>
                        In order to generate this indicative price the following parameters were used. A minimum sample size of at least 100 quotations or sales, generated over the 3 months of Jan 2023 to March 2023 and spanning a variety of insurable risks. Optional Add-ons were not included.<br/><br/>
                        NB: Insurance is based on individual circumstances and any prices or eligibility are specific to each driver.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-column">
                  <div className="d-flex flex-column-reverse flex-sm-column-reverse flex-md-column-reverse flex-lg-row
                    flex-xl-row py-3 justify-content-md-center">
                    <Link to={
                      {
                        pathname: singleCarResultUrl
                      }
                    } id={"singleCarResult-" + offer?.id}
                          className='text-center mb-1 mx-md-3 cta cta--blue-outline'>
                      <span>Back</span>
                    </Link>
                    <Link to={
                      {
                        pathname: '/preferences/signup',
                        search: new URLSearchParams({
                          marketingChannel: MarketingChannel.TCO,
                          linkedOfferId: id
                        }).toString()
                      }
                    } id={"emailCostsToMe-" + offer?.id} className='text-center mb-1 mx-md-3 cta cta--blue-outline'>
                      <span className="me-4">Email costs to me</span>
                      <span className="icon icon__arrow--right">
                        <svg focusable="false" width="16" height="9" viewBox="0 0 16 9" data-di-res-id="169be71e-29252940"
                            data-di-rand="1656653705872">
                          <path d="M8,8.4 15.4,1.5 14.5,0.4 8,5.3 1.5,0.4 0.6,1.5" stroke="" fill="#006CF4"></path>
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <div className="pt-4">
              <hr className="bg-dusty-mint m-auto col-11"/>
            </div>
            <div className="d-flex flex-row justify-content-center position-relative">
              <div className="col-lg-10 col-md-10 "><p
                className="comparison-properties mb-0 ff-livvic">Car lease</p>
              </div>
              <OverlayTrigger
                trigger="focus"
                placement="bottom"
                overlay={
                  <Popover id="carLeasePopover">
                    <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                    <Popover.Body>
                      Based on a personal contract hire lease for the given term and mileage from a panel of five lenders.
                      Excludes initial rental fee and processing fees.
                    </Popover.Body>
                  </Popover>
                }
              >
                <button className="info-icon d-none d-md-block comparison-popover-page"
                        onClick={(e) => e.currentTarget.focus()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                       viewBox="0 0 122.8 123.3">
                    <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                             strokeMiterlimit="10"
                             cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                    <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                    <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                  </svg>
                </button>
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-row position-relative mb-lg-0 mb-md-0 mb-3">
              <div className="col-6 comparison ff-mulish">&#163;{evOffer?.leaseMonthlyCost}</div>
              <OverlayTrigger
                trigger="focus"
                placement="bottom"
                overlay={
                  <Popover id="carLeasePopover">
                    <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                    <Popover.Body>
                      Based on a personal contract hire lease for the given term and mileage from a panel of five lenders.
                      Excludes initial rental fee and processing fees.
                    </Popover.Body>
                  </Popover>
                }
              >
                <button
                  className="info-icon d-md-none position-absolute top-50 start-50 translate-middle svg-line-height"
                  onClick={(e) => e.currentTarget.focus()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                       viewBox="0 0 122.8 123.3">
                    <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                             strokeMiterlimit="10"
                             cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                    <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                    <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                  </svg>
                </button>
              </OverlayTrigger>
              <div className="col-6 comparison ff-mulish">&#163;{iceOffer?.leaseMonthlyCost}</div>
            </div>
            <div className="pt-lg-5 pt-md-5 pt-sm-3">
              <hr className="bg-dusty-mint m-auto col-11"/>
            </div>
            <div className="d-flex flex-row justify-content-center position-relative">
              <div className="col-lg-10 col-md-10"><p
                className="comparison-properties mb-0 ff-livvic">Electricity / Fuel</p>
              </div>
              <OverlayTrigger
                trigger="focus"
                placement="bottom"
                overlay={
                  <Popover id="carLeasePopover">
                    <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                    <Popover.Body>
                      Electricity costs assume that vehicle is charged overnight between 10pm and 8am on a national average rate of
                      &#163;{offers.currentElectricityPrice} and annual mileage given and vehicle kWh/mile.
                      Petrol and diesel costs are based on national average fuels rate of &#163;{offers.currentPetrolPrice} and &#163;{offers.currentDieselPrice} per litre.
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button className="info-icon d-none d-md-block comparison-popover-page" variant="link"
                        onClick={(e) => e.currentTarget.focus()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                       viewBox="0 0 122.8 123.3">
                    <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10" strokeMiterlimit="10"
                             cx="61.4" cy="61.6"
                             rx="56.4" ry="56.6"/>
                    <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                    <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-row position-relative mb-lg-0 mb-md-0 mb-3">
              <div className="col-6 comparison ff-mulish">&#163;{evOffer?.fuelMonthlyCost}</div>
              <OverlayTrigger
                trigger="focus"
                placement="bottom"
                overlay={
                  <Popover id="carLeasePopover">
                    <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                    <Popover.Body>
                      Electricity costs assume that vehicle is charged overnight between 10pm and 8am on a national average rate of
                      &#163;{offers.currentElectricityPrice} and annual mileage given and vehicle kWh/mile.
                      Petrol and diesel costs are based on national average fuels rate of &#163;{offers.currentPetrolPrice} and &#163;{offers.currentDieselPrice} per litre.
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button
                  className="info-icon d-md-none position-absolute top-50 start-50 translate-middle svg-line-height"
                  variant="link" onClick={(e) => e.currentTarget.focus()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                       viewBox="0 0 122.8 123.3">
                    <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10" strokeMiterlimit="10"
                             cx="61.4" cy="61.6"
                             rx="56.4" ry="56.6"/>
                    <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                    <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                  </svg>
                </Button>
              </OverlayTrigger>
              <div className="col-6 comparison ff-mulish">&#163;{iceOffer?.fuelMonthlyCost}</div>
            </div>
            <div className="pt-lg-5 pt-md-5 pt-sm-3">
              <hr className="bg-dusty-mint m-auto col-11"/>
            </div>
            <div className="d-flex flex-row justify-content-center position-relative">
              <div className="col-lg-10 col-md-10 "><p
                className="comparison-properties mb-0 ff-livvic">Car tax</p>
              </div>
              <OverlayTrigger
                trigger="focus"
                placement="bottom"
                overlay={
                  <Popover id="carLeasePopover">
                    <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                    <Popover.Body>
                      Car tax based on vehicle excise duty from gov.uk
                    </Popover.Body>
                  </Popover>
                }
              >
                <button className="info-icon d-none d-md-block comparison-popover-page"
                        onClick={(e) => e.currentTarget.focus()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                       viewBox="0 0 122.8 123.3">
                    <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                             strokeMiterlimit="10"
                             cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                    <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                    <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                  </svg>
                </button>
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-row position-relative mb-lg-0 mb-md-0 mb-3">
              <div className="col-6 comparison ff-mulish">&#163;{evOffer?.carTaxMonthlyCost}</div>
              <OverlayTrigger
                trigger="focus"
                placement="bottom"
                overlay={
                  <Popover id="carLeasePopover">
                    <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                    <Popover.Body>
                      Car tax based on vehicle excise duty from gov.uk
                    </Popover.Body>
                  </Popover>
                }
              >
                <button
                  className="info-icon d-md-none position-absolute top-50 start-50 translate-middle svg-line-height"
                  onClick={(e) => e.currentTarget.focus()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                       viewBox="0 0 122.8 123.3">
                    <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                             strokeMiterlimit="10"
                             cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                    <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                    <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                  </svg>
                </button>
              </OverlayTrigger>
              <div className="col-6 comparison ff-mulish">&#163;{iceOffer?.carTaxMonthlyCost}</div>
            </div>
            <div className="pt-lg-5 pt-md-5 pt-sm-3">
              <hr className="bg-dusty-mint m-auto col-11"/>
            </div>
            <div className="d-flex flex-row justify-content-center position-relative">
              <div className="col-lg-10 col-md-10"><p
                className="comparison-properties mb-0 ff-livvic">Maintenance</p>
              </div>
              <OverlayTrigger
                trigger="focus"
                placement="bottom"
                overlay={
                  <Popover id="carLeasePopover">
                    <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                    <Popover.Body>
                      Based on CBVC data {currentMonth} {currentYear}. Includes all routine servicing, tyres and breakdown cover.
                      Subject to normal wear and tear.
                    </Popover.Body>
                  </Popover>
                }
              >
                <button className="info-icon d-none d-md-block comparison-popover-page"
                        onClick={(e) => e.currentTarget.focus()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                       viewBox="0 0 122.8 123.3">
                    <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                             strokeMiterlimit="10"
                             cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                    <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                    <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                  </svg>
                </button>
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-row position-relative mb-lg-0 mb-md-0 mb-3">
              <div className="col-6 comparison ff-mulish">&#163;{evOffer?.maintenanceCost}</div>
              <OverlayTrigger
                trigger="focus"
                placement="bottom"
                overlay={
                  <Popover id="carLeasePopover">
                    <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                    <Popover.Body>
                      Based on CBVC data {currentMonth} {currentYear}. Includes all routine servicing, tyres and breakdown cover.
                      Subject to normal wear and tear.
                    </Popover.Body>
                  </Popover>
                }
              >
                <button
                  className="info-icon d-md-none position-absolute top-50 start-50 translate-middle svg-line-height"
                  onClick={(e) => e.currentTarget.focus()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                       viewBox="0 0 122.8 123.3">
                    <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                             strokeMiterlimit="10"
                             cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                    <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                    <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                  </svg>
                </button>
              </OverlayTrigger>
              <div className="col-6 comparison ff-mulish">&#163;{iceOffer?.maintenanceCost}</div>
            </div>
            <div className="pt-lg-5 pt-md-5 pt-sm-3">
              <hr className="bg-dusty-mint m-auto col-11"/>
            </div>
            <div className="d-flex flex-row justify-content-center position-relative">
              <div className="col-lg-10 col-md-10"><p
                className="comparison-properties mb-0 ff-livvic">Insurance</p>
              </div>
              <OverlayTrigger
                trigger="focus"
                placement="bottom"
                overlay={
                  <Popover id="carLeasePopover">
                    <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                    <Popover.Body>
                      Estimations are based on data provided by Allianz Insurance using their recent quote
                      and sales information for this vehicle.<br/><br/>

                      In order to generate this indicative price the following parameters were used.
                      A minimum sample size of at least 100 quotations or sales,
                      generated over the 3 months of Jan 2023 to March 2023 and spanning a variety of insurable risks.
                      Optional Add-ons were not included.<br/><br/>

                      NB: Insurance is based on individual circumstances and any prices or eligibility are specific to each driver.
                    </Popover.Body>
                  </Popover>
                }
              >
                <button className="info-icon d-none d-md-block comparison-popover-page"
                        onClick={(e) => e.currentTarget.focus()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                       viewBox="0 0 122.8 123.3">
                    <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                             strokeMiterlimit="10"
                             cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                    <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                    <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                  </svg>
                </button>
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-row position-relative mb-lg-0 mb-md-0 mb-3">
              <div className="col-6 comparison ff-mulish">&#163;{evOffer?.insuranceMonthlyCost}</div>
              <OverlayTrigger
                trigger="focus"
                placement="bottom"
                overlay={
                  <Popover id="carLeasePopover">
                    <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                    <Popover.Body>
                      Estimations are based on data provided by Allianz Insurance using their recent quote
                      and sales information for this vehicle.<br/><br/>

                      In order to generate this indicative price the following parameters were used.
                      A minimum sample size of at least 100 quotations or sales,
                      generated over the 3 months of Jan 2023 to March 2023 and spanning a variety of insurable risks.
                      Optional Add-ons were not included.<br/><br/>

                      NB: Insurance is based on individual circumstances and any prices or eligibility are specific to each driver.
                    </Popover.Body>
                  </Popover>
                }
              >
                <button
                  className="info-icon d-md-none position-absolute top-50 start-50 translate-middle svg-line-height"
                  onClick={(e) => e.currentTarget.focus()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                       viewBox="0 0 122.8 123.3">
                    <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                             strokeMiterlimit="10"
                             cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                    <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                    <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                  </svg>
                </button>
              </OverlayTrigger>
              <div className="col-6 comparison ff-mulish">&#163;{iceOffer?.insuranceMonthlyCost}</div>
              </div>
              <div className="pt-lg-5 pt-md-5 pt-sm-3">
            </div>
            <div>
              <div className="d-flex flex-row justify-content-center upfront-negative-margin">
                <div className="col-12 upfront-costs-box-border-wrap">
                  <div className="bg-white upfront-costs-box border-margin">
                    <div className="text-md-start pb-2 pt-2 col-12">
                      <div className="position-relative">
                        <h2 className="fw-bold mb-0 pt-1 text-center costs">
                          Upfront costs
                        </h2>
                        <h3 className="fw-bold mt-1 mb-0 pb-1 text-center">
                          (not included in the monthly estimates)
                        </h3>
                      </div>
                    </div>
                    <hr className="bg-ocean-blue m-auto col-11"/>
                    <div className="d-flex flex-row justify-content-center position-relative">
                      <div className="col-lg-10 col-md-10"><p
                          className="comparison-properties mb-0 ff-livvic">Home charger</p>
                      </div>
                      <OverlayTrigger
                          trigger="focus"
                          placement="bottom"
                          overlay={
                            <Popover id="carLeasePopover">
                              <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                              <Popover.Body>
                                Based on an ElectriX Indra charger with standard installation at
                                &#163;{offers.homeChargerCost} inc VAT.
                                Final costs may differ based on your circumstances.
                              </Popover.Body>
                            </Popover>
                          }
                      >
                        <button className="info-icon d-none d-md-block comparison-popover-page"
                                onClick={(e) => e.currentTarget.focus()}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                               viewBox="0 0 122.8 123.3">
                            <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                     strokeMiterlimit="10"
                                     cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                            <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                            <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                          </svg>
                        </button>
                      </OverlayTrigger>
                    </div>
                    <div className="d-flex flex-row position-relative mb-lg-0 mb-md-0 mb-3">
                      <div className="col-6 comparison ff-mulish">&#163;{offers.homeChargerCost}</div>
                      <OverlayTrigger
                          trigger="focus"
                          placement="bottom"
                          overlay={
                            <Popover id="carLeasePopover">
                              <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                              <Popover.Body>
                                Based on an ElectriX Indra charger with standard installation at
                                &#163;{offers.homeChargerCost} inc VAT.
                                Final costs may differ based on your circumstances.
                              </Popover.Body>
                            </Popover>
                          }
                      >
                        <button
                            className="info-icon d-md-none position-absolute top-50 start-50 translate-middle svg-line-height"
                            onClick={(e) => e.currentTarget.focus()}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                               viewBox="0 0 122.8 123.3">
                            <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                     strokeMiterlimit="10"
                                     cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                            <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                            <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                          </svg>
                        </button>
                      </OverlayTrigger>
                      <div className="col-6 comparison ff-mulish">Not applicable</div>
                    </div>
                    <div className="pt-lg-4 pt-md-4 pt-sm-2">
                      <hr className="bg-ocean-blue m-auto col-11"/>
                    </div>
                    <div className="d-flex flex-row justify-content-center position-relative">
                      <div className="col-lg-10 col-md-10"><p
                          className="comparison-properties mb-0 ff-livvic">Initial rental</p>
                      </div>
                      <OverlayTrigger
                          trigger="focus"
                          placement="bottom"
                          overlay={
                            <Popover id="carLeasePopover">
                              <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                              <Popover.Body>
                                Your initial rental is the first payment that you make towards the lease of your new vehicle,
                                calculated as a multiple of monthly rental payments. The value shown here is 12 months, but
                                can be calculated as 1, 3, 6, 9 or 12, depending on your preference.
                              </Popover.Body>
                            </Popover>
                          }
                      >
                        <button className="info-icon d-none d-md-block comparison-popover-page"
                                onClick={(e) => e.currentTarget.focus()}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                               viewBox="0 0 122.8 123.3">
                            <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                     strokeMiterlimit="10"
                                     cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                            <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                            <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                          </svg>
                        </button>
                      </OverlayTrigger>
                    </div>
                    <div className="d-flex flex-row position-relative mb-lg-0 mb-md-0 mb-3">
                      <div className="col-6 comparison ff-mulish">&#163;{offer?.evOffer.initialRentalCost}</div>
                      <OverlayTrigger
                          trigger="focus"
                          placement="bottom"
                          overlay={
                            <Popover id="carLeasePopover">
                              <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                              <Popover.Body>
                                Your initial rental is the first payment you make towards the lease on your new vehicle.
                                The amount is calculated in multiples of the monthly rental and show here as 12 times the monthly payment value,
                                but can usually be either 1, 3, 6, 9 or 12 times the monthly payment which will be due for the term of your agreement,
                                depending on your preferences.
                              </Popover.Body>
                            </Popover>
                          }
                      >
                        <button
                            className="info-icon d-md-none position-absolute top-50 start-50 translate-middle svg-line-height"
                            onClick={(e) => e.currentTarget.focus()}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                               viewBox="0 0 122.8 123.3">
                            <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                     strokeMiterlimit="10"
                                     cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                            <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                            <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                          </svg>
                        </button>
                      </OverlayTrigger>
                      <div className="col-6 comparison ff-mulish">&#163;{offer?.iceOffer.initialRentalCost}</div>
                    </div>
                    <div className="pt-lg-4 pt-md-4 pt-sm-3"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  )
}

export default Comparison
