import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import {OverlayTrigger, Popover} from "react-bootstrap";
import {useAppSelector} from "../../store/Hooks";
import {selectAllOffers, selectOfferByLinkedOfferId} from "../../slices/OfferSlice";
import {MarketingChannel} from "../../model/MarketingChannel";
import {getVehicleImageSrc} from "../../helpers/Helper";

export const ComparisonModal = (props: any) => {
  const {show, handleClose} = props
  const id = useParams().id as string
  const offer = useAppSelector(state => selectOfferByLinkedOfferId(state, parseInt(id)))
  const offers = useAppSelector(state => selectAllOffers(state))
  const evOffer = offer?.evOffer
  const iceOffer = offer?.iceOffer
  const evModel = evOffer !== undefined ? evOffer?.vehicle.model : ''
  const evManufacturer = evOffer !== undefined ? evOffer?.vehicle.manufacturer : ''

  const navigate = useNavigate();

  const redirectToSignupPage = () => {
    navigate({
      pathname: '/preferences/signup',
      search: new URLSearchParams({marketingChannel: MarketingChannel.TCO, make: evManufacturer, model: evModel}).toString()
    })
  }
  const onModalShown = () => {
    console.log(JSON.stringify(offer));
  }

  return (
    <Modal show={show}
           onHide={handleClose}
           size="lg"
           fullscreen="md-down"
           centered
           className="m-auto"
           onEntered ={onModalShown}>
      <div className="mt-0 w-100">
        <div className="modal-content">
          <div className="modal-header pb-2 pt-2">
            <div className="col-2 hover-div row me-0 ms-auto justify-content-end order-2">
              <a href="/"
                 className="d-none d-md-block modal-title text-end right href-close-btn ff-livvic col-2 me-1 pe-3"
                 aria-label="Close"
                 data-bs-dismiss="modal" id="comparisonModalLabel" onClick={handleClose}>Close</a>
              <button type="button" className="btn-close d-none d-md-block col-2 m-0 ms-4 p-1 text-end"
                      aria-label="Close"
                      data-bs-dismiss="modal" onClick={handleClose}></button>
            </div>
            <div aria-label="Close" data-bs-dismiss="modal" className="d-md-none d-flex order-1 pb-0">
              <svg className="dismiss-modal" type="svg" width="22" height="22" viewBox="0 0 22 22"
                   fill="none" overflow="hidden" stroke="transparent"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.0007 21.6663L0.333984 10.9997L11.0007 0.333008L12.4007 1.73301L4.13398 9.99967H21.6673V11.9997H4.13398L12.4007 20.2663L11.0007 21.6663Z"/>
              </svg>
            </div>
          </div>
          <div className="modal-body p-0">
            <div className="container-fluid px-0">
              <div className="d-flex flex-row justify-content-between">
                <div
                  className="d-flex flex-column col-lg-6 justify-content-between comparison-header-bg comparison-modal-header-sizes text-center left">
                  <p className="mb-0 compared-car fs-8 fs-sm-8 fs-lg-8 ff-mulish">&nbsp;</p>
                  <p className="comparison-car-label left pt-0 pb-lg-1 mb-0 fs-7 fs-sm-7 fs-lg-7 ff-livvic vehicle-manufacturer-fs">
                    {evManufacturer} {evModel}
                  </p>
                  <div className="mb-4 car-info-container">
                    {evOffer?.vehicle && <img src={getVehicleImageSrc(evOffer?.vehicle)}
                                              className="image-mobile-comparison--size img-fluid img-form bg-white"
                                              alt="Electric Vehicle"/>}

                    <p className="comparison-car-info d-none d-sm-block mb-1 ff-mulish">
                      {evOffer?.vehicle.derivative}
                    </p>
                    <div className="pt-2 pt-lg-0 pt-md-0 container">
                      <div className="row justify-content-center comparison-image-height">
                        <div className="col-sm-auto col-md-auto col-lg-auto">
                          <img src='/account-img/icons/electric-sign.svg'
                               className="comparison-svg-align"
                               alt='Electric'/>
                        </div>
                        <div className="col-sm-auto col-md-auto col-lg-auto pt-0">
                          <p className="comparison-car-info ff-mulish">ELECTRIC</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex flex-column col-lg-6 justify-content-between comparison-header-bg comparison-modal-header-sizes text-center right">
                  <p className="mb-0 compared-car fs-8 fs-sm-8 fs-lg-8 ff-mulish">COMPARISON CAR</p>
                  <p className="comparison-car-label right pt-0 pb-lg-1 mb-0 fs-7 fs-sm-7 fs-lg-7 ff-livvic vehicle-manufacturer-fs">
                    {iceOffer?.vehicle.manufacturer} {iceOffer?.vehicle.model}
                  </p>
                  <div className="mb-4 car-info-container">
                    {iceOffer?.vehicle && <img src={getVehicleImageSrc(iceOffer?.vehicle)}
                                               className="image-mobile-comparison--size img-fluid img-form bg-white"
                                               alt="Comparison Vehicle"/>}

                    <p className="comparison-car-info d-none d-sm-block mb-1 ff-mulish">
                      {iceOffer?.vehicle.derivative}
                    </p>
                    <div className="pt-2 pt-lg-0 pt-md-0 container">
                      <div className="row justify-content-center comparison-image-height">
                        <div className="col-sm-auto col-lg-auto col-md-auto">
                          <img src='/account-img/icons/gas-pump-sign.svg'
                               className="comparison-svg-align" alt='Gas Pump'/>
                        </div>
                        <div className="col-sm-auto col-md-auto col-lg-auto pt-0">
                          <p className="comparison-car-info ff-mulish">{iceOffer?.vehicle.fuelType?.replaceAll('_', ' ')}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="scrollable-body scrollable-body-lg scrollbar-primary bg-white">
                <div className="text-center">
                  <div className="pt-4">
                    <hr className="bg-dusty-mint m-auto col-11"/>
                  </div>
                  <div className="d-flex flex-row justify-content-center position-relative">
                    <div className="col-lg-10 col-md-10 "><p
                      className="comparison-properties mb-0 ff-livvic">Car lease</p>
                    </div>
                    <OverlayTrigger
                      trigger="focus"
                      placement="bottom"
                      overlay={
                        <Popover id="carLeasePopover">
                          <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                          <Popover.Body>
                            Based on a personal contract hire from a panel of leasing
                            providers.
                            Excludes one-off Initial Payment and Processing Fee.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <button className="info-icon d-none d-md-block comparison-popover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                             viewBox="0 0 122.8 123.3">
                          <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                   strokeMiterlimit="10"
                                   cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                          <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                          <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                        </svg>
                      </button>
                    </OverlayTrigger>
                  </div>
                  <div className="d-flex flex-row position-relative mb-lg-0 mb-md-0 mb-3">
                    <div className="col-6 comparison ff-mulish">&#163;{evOffer?.leaseMonthlyCost}</div>
                    <OverlayTrigger
                      trigger="focus"
                      placement="bottom"
                      overlay={
                        <Popover id="carLeasePopover">
                          <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                          <Popover.Body>
                            Based on a personal contract hire from a panel of leasing
                            providers.
                            Excludes one-off Initial Payment and Processing Fee.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <button
                        className="info-icon d-md-none position-absolute top-50 start-50 translate-middle svg-line-height">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                             viewBox="0 0 122.8 123.3">
                          <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                   strokeMiterlimit="10"
                                   cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                          <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                          <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                        </svg>
                      </button>
                    </OverlayTrigger>
                    <div className="col-6 comparison ff-mulish">&#163;{iceOffer?.leaseMonthlyCost}</div>
                  </div>
                  <div className="pt-lg-5 pt-md-5 pt-sm-3">
                    <hr className="bg-dusty-mint m-auto col-11"/>
                  </div>
                  <div className="d-flex flex-row justify-content-center position-relative">
                    <div className="col-lg-10 col-md-10"><p
                      className="comparison-properties mb-0 ff-livvic">Electricity / Fuel</p>
                    </div>
                    <OverlayTrigger
                      trigger="focus"
                      placement="bottom"
                      overlay={
                        <Popover id="carLeasePopover">
                          <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                          <Popover.Body>
                            Electricity costs assume that vehicle is charged overnight between 10pm and 8am on a national average rate of
                            &#163;{offers.currentElectricityPrice} and annual mileage given and vehicle kWh/mile.
                            Petrol and diesel costs are based on national average fuels rate of &#163;{offers.currentPetrolPrice} and &#163;{offers.currentDieselPrice} per litre.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <button className="info-icon d-none d-md-block comparison-popover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                             viewBox="0 0 122.8 123.3">
                          <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                   strokeMiterlimit="10"
                                   cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                          <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                          <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                        </svg>
                      </button>
                    </OverlayTrigger>
                  </div>
                  <div className="d-flex flex-row position-relative mb-lg-0 mb-md-0 mb-3">
                    <div className="col-6 comparison ff-mulish">&#163;{evOffer?.fuelMonthlyCost}</div>
                    <OverlayTrigger
                      trigger="focus"
                      placement="bottom"
                      overlay={
                        <Popover id="carLeasePopover">
                          <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                          <Popover.Body>
                            Electricity costs assume that vehicle is charged overnight on a national average rate of
                            &#163;{offers.currentElectricityPrice} and annual mileage given and vehicle kWh/mile.
                            Petrol and diesel costs are based on national average fuels rate of &#163;{offers.currentPetrolPrice} and &#163;{offers.currentDieselPrice} per litre.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <button
                        className="info-icon d-md-none position-absolute top-50 start-50 translate-middle svg-line-height">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                             viewBox="0 0 122.8 123.3">
                          <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                   strokeMiterlimit="10"
                                   cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                          <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                          <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                        </svg>
                      </button>
                    </OverlayTrigger>
                    <div className="col-6 comparison ff-mulish">&#163;{iceOffer?.fuelMonthlyCost}</div>
                  </div>
                  <div className="pt-lg-5 pt-md-5 pt-sm-3">
                    <hr className="bg-dusty-mint m-auto col-11"/>
                  </div>
                  <div className="d-flex flex-row justify-content-center position-relative">
                    <div className="col-lg-10 col-md-10 "><p
                      className="comparison-properties mb-0 ff-livvic">Car tax</p>
                    </div>
                    <OverlayTrigger
                      trigger="focus"
                      placement="bottom"
                      overlay={
                        <Popover id="carLeasePopover">
                          <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                          <Popover.Body>
                            Car tax based on vehicle excise duty from gov.uk
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <button className="info-icon d-none d-md-block comparison-popover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                             viewBox="0 0 122.8 123.3">
                          <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                   strokeMiterlimit="10"
                                   cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                          <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                          <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                        </svg>
                      </button>
                    </OverlayTrigger>
                  </div>
                  <div className="d-flex flex-row position-relative mb-lg-0 mb-md-0 mb-3">
                    <div className="col-6 comparison ff-mulish">&#163;{evOffer?.carTaxMonthlyCost}</div>
                    <OverlayTrigger
                      trigger="focus"
                      placement="bottom"
                      overlay={
                        <Popover id="carLeasePopover">
                          <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                          <Popover.Body>
                            Car tax based on vehicle excise duty from gov.uk
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <button className="info-icon d-md-none position-absolute top-50 start-50 translate-middle svg-line-height">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                             viewBox="0 0 122.8 123.3">
                          <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                   strokeMiterlimit="10"
                                   cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                          <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                          <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                        </svg>
                      </button>
                    </OverlayTrigger>
                    <div className="col-6 comparison ff-mulish">&#163;{iceOffer?.carTaxMonthlyCost}</div>
                  </div>
                  <div className="pt-lg-5 pt-md-5 pt-sm-3">
                    <hr className="bg-dusty-mint m-auto col-11"/>
                  </div>
                  <div className="d-flex flex-row justify-content-center position-relative">
                    <div className="col-lg-10 col-md-10"><p
                      className="comparison-properties mb-0 ff-livvic">Maintenance</p>
                    </div>
                    <OverlayTrigger
                      trigger="focus"
                      placement="bottom"
                      overlay={
                        <Popover id="carLeasePopover">
                          <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                          <Popover.Body>
                            Based on ...
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <button className="info-icon d-none d-md-block comparison-popover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                             viewBox="0 0 122.8 123.3">
                          <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                   strokeMiterlimit="10"
                                   cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                          <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                          <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                        </svg>
                      </button>
                    </OverlayTrigger>
                  </div>
                  <div className="d-flex flex-row position-relative mb-lg-0 mb-md-0 mb-3">
                    <div className="col-6 comparison ff-mulish">&#163;{evOffer?.maintenanceCost}</div>
                    <OverlayTrigger
                      trigger="focus"
                      placement="bottom"
                      overlay={
                        <Popover id="carLeasePopover">
                          <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                          <Popover.Body>
                            Based on ...
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <button
                        className="info-icon d-md-none position-absolute top-50 start-50 translate-middle svg-line-height">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                             viewBox="0 0 122.8 123.3">
                          <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                   strokeMiterlimit="10"
                                   cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                          <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                          <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                        </svg>
                      </button>
                    </OverlayTrigger>
                    <div className="col-6 comparison ff-mulish">&#163;{iceOffer?.maintenanceCost}</div>
                  </div>
                  <div className="pt-lg-5 pt-md-5 pt-sm-3">
                    <hr className="bg-dusty-mint m-auto col-11"/>
                  </div>
                  <div className="d-flex flex-row justify-content-center position-relative">
                    <div className="col-lg-10 col-md-10"><p
                      className="comparison-properties mb-0 ff-livvic">Insurance</p>
                    </div>
                    <OverlayTrigger
                      trigger="focus"
                      placement="bottom"
                      overlay={
                        <Popover id="carLeasePopover">
                          <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                          <Popover.Body>
                            Based on a personal contract hire from a panel of leasing
                            providers. Excludes one-off Initial Payment and Processing Fee.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <button className="info-icon d-none d-md-block comparison-popover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                             viewBox="0 0 122.8 123.3">
                          <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                   strokeMiterlimit="10"
                                   cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                          <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                          <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                        </svg>
                      </button>
                    </OverlayTrigger>
                  </div>
                  <div className="d-flex flex-row position-relative mb-lg-0 mb-md-0 mb-3">
                    <div className="col-6 comparison ff-mulish">&#163;{evOffer?.insuranceMonthlyCost}</div>
                    <OverlayTrigger
                      trigger="focus"
                      placement="bottom"
                      overlay={
                        <Popover id="carLeasePopover">
                          <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                          <Popover.Body>
                            Based on a personal contract hire from a panel of leasing
                            providers. Excludes one-off Initial Payment and Processing Fee.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <button
                        className="info-icon d-md-none position-absolute top-50 start-50 translate-middle svg-line-height">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                             viewBox="0 0 122.8 123.3">
                          <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                   strokeMiterlimit="10"
                                   cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                          <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                          <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                        </svg>
                      </button>
                    </OverlayTrigger>
                    <div className="col-6 comparison ff-mulish">&#163;{iceOffer?.insuranceMonthlyCost}</div>
                  </div>
                  <div className="pt-lg-5 pt-md-5 pt-sm-3">
                    <hr className="bg-dusty-mint m-auto col-11"/>
                  </div>
                  <div className="d-flex flex-row justify-content-center position-relative">
                    <div className="col-lg-10 col-md-10"><p
                      className="comparison-properties mb-0 ff-livvic">Home charger</p>
                    </div>
                    <OverlayTrigger
                      trigger="focus"
                      placement="bottom"
                      overlay={
                        <Popover id="carLeasePopover">
                          <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                          <Popover.Body>
                            Based on an ElectriX Indra charger with standard installation at
                            &#163;{offers.homeChargerCost} inc VAT.
                            Final costs may differ based on your circumstances.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <button className="info-icon d-none d-md-block comparison-popover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                             viewBox="0 0 122.8 123.3">
                          <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                   strokeMiterlimit="10"
                                   cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                          <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                          <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                        </svg>
                      </button>
                    </OverlayTrigger>
                  </div>
                  <div className="d-flex flex-row position-relative pb-lg-5 pb-md-5 pb-sm-3">
                    <div className="col-6 comparison ff-mulish">&#163;{offers.homeChargerCost}</div>
                    <OverlayTrigger
                      trigger="focus"
                      placement="bottom"
                      overlay={
                        <Popover id="carLeasePopover">
                          <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                          <Popover.Body>
                            Based on an ElectriX Indra charger with standard installation at
                            &#163;{offers.homeChargerCost} inc VAT.
                            Final costs may differ based on your circumstances.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <button
                        className="info-icon d-md-none position-absolute top-50 start-50 translate-middle svg-line-height">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                             viewBox="0 0 122.8 123.3">
                          <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10"
                                   strokeMiterlimit="10"
                                   cx="61.4" cy="61.6" rx="56.4" ry="56.6"/>
                          <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                          <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                        </svg>
                      </button>
                    </OverlayTrigger>
                    <div className="col-6 comparison ff-mulish">Not applicable</div>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center gradient-padding px-0 px-lg-4 pt-4 pt-lg-0 pt-md-0 pb-lg-4">
                  <div className="gradient-box-border-wrap col-12">
                    <div className="gradient-box bg-white">
                      <div className="d-flex flex-row justify-content-center inside-gradient-row inside-gradient-row-lg">
                        <div className="col-9 text-center">
                          <h4 className="comparison ff-livvic">Estimated total monthly costs</h4>
                        </div>
                      </div>
                      <div className="d-flex flex-row inside-gradient-row inside-gradient-row-lg text-center">
                        <div className="col-6 comparison-border-right">
                          <img src='/account-img/icons/like.svg' className={'icon-padding-bottom ' + (evOffer?.estimatedMonthlyCost! < iceOffer?.estimatedMonthlyCost! ? '' : 'd-none')}
                               alt='Like'/>
                        </div>
                        <div className="col-6 comparison-border-left">
                          <img src='/account-img/icons/like.svg' className={"icon-padding-bottom " + (iceOffer?.estimatedMonthlyCost! < evOffer?.estimatedMonthlyCost! ? '' : 'd-none')}
                               alt='Like'/>
                        </div>
                      </div>
                      <div
                        className="d-flex flex-row inside-gradient-row inside-gradient-row-lg text-center">
                        <div className="col-6 comparison-border-right text-center"><h2
                          className="comparison text-center ff-livvic">&#163;{evOffer?.estimatedMonthlyCost}</h2></div>
                        <div className="col-6 comparison-border-left text-center"><h2
                          className="comparison text-center ff-livvic">&#163;{iceOffer?.estimatedMonthlyCost}</h2></div>
                      </div>
                      <div className="d-flex flex-row justify-content-center">
                        <div className="col-11 text-center">
                          <p className="costs-calculation-info text-center ff-mulish">Costs
                            are
                            calculated on leasing
                            for {offers.term} months, and driving {offers.mileage.toLocaleString()} miles per year.</p>
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-center d-none d-md-block">
                        <div className="col-12 text-center px-4">
                          <p className="p-2 ff-mulish bg-cool-grey">
                            <strong>Costs shown are indicative based on market averages and may differ
                              based on your personal circumstances.
                              </strong>
                          </p>
                        </div>
                      </div>
                      <div className="d-none d-md-block px-4 pb-2">
                        <hr className="container-lg my-0 bg-dusty-mint"/>
                        <div id="viewTheseCostsInDetailTablet">
                          <h2 id="viewCalculationInDetailTabletHeading">
                            <button className="collapse-button collapsed" type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#viewCalculationInDetailTabletText"
                                    aria-expanded="false"
                                    aria-controls="viewCalculationInDetailTabletText">
                                                            <span
                                                              className="fw-bold ps-3">How we calculate these costs?</span>
                            </button>
                          </h2>
                          <div id="viewCalculationInDetailTabletText" className="collapse"
                               aria-labelledby="viewCalculationInDetailTabletHeading"
                               data-bs-parent="#viewCalculationInDetailTabletHeading">
                            <div className="py-1 ps-4 pe-4">
                              <p className="small">
                                                                <span
                                                                  className="fw-bold ff-livvic">Car lease</span><br/>
                                This price doesn’t include the Initial Payment of
                                &#163;{evOffer?.initialRentalCost}, and a Processing Fee
                                of &#163;{evOffer?.contractFee}.
                                All prices shown subject to change at any time and would
                                be
                                subject to finance approval and vehicle availability.
                                Failure to maintain payments may result in termination
                                of
                                your agreement and the vehicle being returned, this
                                could effect your credit rating and make it more
                                difficult
                                to obtain credit in the future. All prices correct
                                at time of publication and maybe withdrawn without prior
                                notice. Personal Lease prices include VAT.
                                When pursing a formal quote, you should try and estimate
                                the
                                distance you will travel as accurately as
                                possible to try and avoid excess mileage charges. Excess
                                mileage charge will apply and will be detailed
                                in any formal quotation. Damage charges may apply.
                              </p>
                              <p className="small">
                              <span className="fw-bold ff-livvic">Insurance</span><br/>
                                Prices taken Sept 2022 and are based on a randomised sample of drivers between 45 - 63 years old across the UK.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex p-4 justify-content-center">
                        <div
                          className='d-block text-start m-0 w-100 w-md-auto cta cta--blue-outline'
                          onClick={redirectToSignupPage} data-bs-dismiss='modal'>
                          <span className="me-4">Email costs to me</span>
                          <span className="icon icon__arrow--right">
                                                      <svg focusable="false" width="16" height="9" viewBox="0 0 16 9"
                                                           data-di-res-id="169be71e-29252940"
                                                           data-di-rand="1656653705872">
                                                        <path d="M8,8.4 15.4,1.5 14.5,0.4 8,5.3 1.5,0.4 0.6,1.5"
                                                              stroke="" fill="#006CF4"></path>
                                                      </svg>
                                                    </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ComparisonModal;
