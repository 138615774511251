export class ProblemDetail {
    status: number
    detail: string
    problems: Message[]
}

export class Message {
    parameter: string
    text: string
    level: Level
}

export enum Level {
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
    UNKNOWN = "unknown"
}
