export class Subscription {
  email?: string
  title?: string
  firstName?: string
  lastName?: string
  middleName?: string
  contactPreference?: string
  marketingChannel?: string
  page?: string
  linkedOfferId?: number

  businessName?: string
  department?: string
  jobTitle?: string
  mobileNumber?: string
  phoneNumber?: string
  numberOfEmployees?: number
  annualMileage?: number
  tradingMoreThan2Years?: boolean


  newsletters?: boolean
  specialOffers?: boolean
  events?: boolean
}
