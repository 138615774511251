export const Footer = () => {

  return (
    <footer className='container-lg small bg-white subscription-footer' id="subscription-footer">
      <ul className='list-unstyled m-0 pt-4 pb-2 d-flex flex-wrap'>
        <li><a href='https://www.lvelectrix.co.uk/terms' className='me-2'>Legal</a>|</li>
        <li><a href='https://www.lvelectrix.co.uk/accessibility' className='mx-2'>Accessibility</a>|</li>
        <li><a href='https://www.lvelectrix.co.uk/privacy-notice' className='ms-2'>Privacy</a></li>
      </ul>
      <div>
        <p className='mb-2'>
          ElectriX is provided by the Liverpool Victoria General Insurance Group (LVGIG), which is part of
          the Allianz Group. You can find more information at <a
          href='https://www.lv.com/insurance/terms/lv-companies'>www.lv.com/insurance/terms/lv-companies.</a>
          The data controller of any personal information given to us about you is LVIC. You can contact
          us by writing to: Data Protection Officer, 57 Ladymead, Guildford, Surrey, GU1 1DB, or
          via email at <a href='mailto: GIdataprotection@LV.co.uk'>GIdataprotection@LV.co.uk.</a>
        </p>
        <p>
          ElectriX is a trading name of Buddies Enterprises Limited and cover is arranged and
          underwritten by Liverpool Victoria Insurance Company Limited (LVIC), both of which are part of
          the Liverpool Victoria General Insurance Group of companies (LVGIG). ElectriX is not a lender and
          introduces customers to CBVC Vehicle Management Ltd, who are a credit broker that use a panel of lenders.
          ElectriX will receive a commission for introductions that result in credit agreements being entered into.
          Buddies Enterprises Limited registered in England and Wales number 4013396 is authorised
          and regulated by the Financial Conduct Authority, register number 514428 and LVIC registered in
          England and Wales number 3232514 is authorised by the Prudential Regulation Authority and regulated
          by the Financial Conduct Authority and the Prudential Regulation Authority, register number 202965.
          Both companies registered address is: 57 Ladymead, Guildford GU1 1DB. Tel. 0330 1239970
        </p>
      </div>
    </footer>
  )
}

export default Footer
