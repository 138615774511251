import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Paginator from "./Paginator";
import {PageableResponse} from "../model";
import { useDebounce } from 'use-debounce';

interface IProps {
    title: string
    dataProvider: (page: number, search?: string) => Promise<PageableResponse>
    dataCallback: Dispatch<SetStateAction<any>>
    hasDeleteBeenCalled?: boolean
}

export const PageableSection : React.FunctionComponent<IProps> = props => {
    const [search, setSearch] = useState("")
    const [searchBounced] = useDebounce(search, 500)
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [numberOfPages, setNumberOfPages] = useState(1)
    const [error, setError] = useState(false)
    const [beenDeleted, setBeenDeleted] = useState(props.hasDeleteBeenCalled)
    const [oldSearch, setOldSearch] = useState('')

    const hasTitle: boolean = props.title.length > 0;

    useEffect(() => {
        let isMounted = true

        if (beenDeleted) {
            setBeenDeleted(false)
        }

        setLoading(true)

        props.dataProvider(currentPage, searchBounced).then(data => {
            if (isMounted) {
                props.dataCallback(data)
                setNumberOfPages(data.totalPages)
                setLoading(false)
            }
        }).catch(() => {
            if (isMounted) {
                setError(true)
                setLoading(false)
            }
        }).finally(() => {
            if (isMounted && !error && oldSearch !== searchBounced) {
                setCurrentPage(0)
                setOldSearch(searchBounced)
            }
        })

        return () => {
            isMounted = false
        }
    }, [setLoading, currentPage, searchBounced, beenDeleted, props.hasDeleteBeenCalled])

    return (
        <>
            {hasTitle ? <><h1 className="h4">{props.title}</h1>
                <hr/>
            </> : ""}
            <div className="row">
                <div className="col-md-8">
                    <Paginator
                        numberOfPages={numberOfPages}
                        currentPage={currentPage}
                        onChange={(page) => { setCurrentPage(page)}}/>
                </div>
                <div className="col-md-4">
                    <input
                        placeholder="Search"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        type="search"
                        className="form-control rounded-pill"
                    />
                </div>
            </div>
            {loading ?
                <div className="loader">Loading...</div> :
                error ? (
                    <div className="text-danger">An Error Occurred</div>
                ) : (
                    props.children
                )
            }
            <Paginator
                numberOfPages={numberOfPages}
                currentPage={currentPage}
                onChange={(page) => { setCurrentPage(page)}}/>
        </>
    )
}
