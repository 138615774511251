import {throwErrorOnNotOk} from "../helpers/Helper";

export const fetchOffers = async () => {
    const response = await fetch('/content/offers.json')
    const result = await response.json()

    throwErrorOnNotOk(response)

    return result
}

export const fetchOffer = async (linkedOfferId: number) => {
    const response = await fetch('/api/offers/'+ linkedOfferId)
    const result = await response.json()

    throwErrorOnNotOk(response)

    return result
}