import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {Link} from "react-router-dom";

export const SiteMap = ({ breadcrumbHrefIn, name}:{breadcrumbHrefIn?: string, name?: string}) => {
  let items = []
    switch (true) {
        case breadcrumbHrefIn?.includes("/single-car-result"):
            items.push({href: "/vehicle-gallery", name: "Compare costs"})
            items.push({href: breadcrumbHrefIn, name: name})
            break;
        case "/vehicle-gallery" === breadcrumbHrefIn:
            items.push({href: breadcrumbHrefIn, name: "Compare costs"})
            break;
        default:
            break;
    }
    return (
        <Breadcrumb>
          <Breadcrumb.Item href="https://www.lvelectrix.co.uk">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="https://www.lvelectrix.co.uk/costs">Costs</Breadcrumb.Item>
          {items.map((item, index) =>
            item.href === breadcrumbHrefIn? (
              <Breadcrumb.Item key={index} active>{item.name}</Breadcrumb.Item>
                ) : (
                  <Breadcrumb.Item key={index} linkProps={{to: item.href}} linkAs={Link}>
                      {item.name}
                  </Breadcrumb.Item>
                )
            )}
        </Breadcrumb>
    );
};
export default SiteMap;