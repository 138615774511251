import React from 'react'
import './sass/index.scss'
import Application from "./components/Application"
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { store } from "./store/Store";
import {createRoot} from "react-dom/client";

if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
    TagManager.initialize({
        gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
    })
}

//Needs to be added before google tag manager
const script = document.createElement("script")
script.src = "//tmsgi.lv.com/liverpoolvictoria/electrix/Bootstrap.js"
script.async = true
document.head.prepend(script)

const container = document.getElementById('root');
if (container === null) {
    throw new Error("Could not found root element by id in index.html file!");
}
const root = createRoot(container);
root.render(<Provider store={store}><Application /></Provider>);
