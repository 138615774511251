import { Subscription } from "../model/Subscription"
import { Unsubscription } from "../model/Unsubscription"
import {plainToInstance} from "class-transformer";
import {throwErrorOnNotOk} from "../helpers/Helper";
import {MarketingFormConfiguration} from "@electrix/shared";
import {SubscriptionRequest} from "../model/SubscriptionRequest";


export const fetchChannelConfig = async (name: string) : Promise<MarketingFormConfiguration> => {
    const response = await fetch('/api/channel/' + name)
    const json = await response.json()
    throwErrorOnNotOk(response)
    return json
}

export const fetchSubscription = async (identity: string) => {
    let params = ''
    if (identity) {
        params = '?overwrite_electrix_identity=' + identity
    }

    const response = await fetch('/api/subscription' + params)
    const json = await response.json()

    throwErrorOnNotOk(response)

    return plainToInstance(Subscription, json)
}

export const subscribe = async (subscription: SubscriptionRequest) => {
    const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(subscription)
    })

    throwErrorOnNotOk(response)
}

export const unsubscribe = async (unsubscription: Unsubscription) => {
    const response = await fetch('/api/unsubscribe', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(unsubscription)
    })

    throwErrorOnNotOk(response)
}
