import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'

import Preferences from './preferences/Preferences'
import Unsubscribe from "./preferences/Unsubscribe";
import ExternalRedirectPage from "./ExternalRedirectPage";
import VehicleGallery from "./VehicleGallery";
import SingleCarResult from "./SingleCarResult";
import Comparison from "./Comparison";
import ScrollToTop from "../helpers/ScrollToTop";
import {MarketingFormPreview} from "./preferences/MarketingFormPreview";
import {SignOffPreview} from "./preferences/SignOffPreview";

const Application = () => (
  <BrowserRouter>
    <ScrollToTop/>
    <Routes>
      <Route path='/preferences/preview' element={<MarketingFormPreview/>}/>
      <Route path='/preferences/preview/signoff' element={<SignOffPreview/>}/>

      <Route path='/preferences/unsubscribe/:identity' element={<Unsubscribe/>}/>
      <Route path='/preferences/unsubscribe' element={<Unsubscribe/>}/>

      <Route path='/preferences/:page/:identity' element={<Preferences/>}/>
      <Route path='/preferences/:page' element={<Preferences/>}/>

      {/* TCO */}
      <Route path='/vehicle-gallery' element={<VehicleGallery/>}/>
      <Route path='/single-car-result/:id' element={<SingleCarResult/>}/>
      <Route path='/comparison/:id' element={<Comparison/>}/>

      {/* Redirect */}
      <Route path='*' element={<ExternalRedirectPage/>}/>
    </Routes>
  </BrowserRouter>
)

export default Application;
