import React from 'react';

export const Charger = () => {
  return (
    <>
      <section className="pt-5 px-4 container-lg">
        <div className="d-md-flex justify-content-md-between align-items-md-center">
          <div className="col-md-5">
            <h2 className="fs-1 mb-4">
              Find out about home chargers
            </h2>
            <div className="d-md-none d-flex flex-column align-items-center">
              <img src="/account-img/before-you-charge-it.png" className="image-mobile--size" alt="Electric Vehicle Charger"/>
            </div>
            <p>
              Top up your car at home with a smart charger.
            </p>
            <ul className="check-list mb-5">
              <li>
                Smart charging – schedule charges when you need them and when energy is cheapest
              </li>
              <li>
                Easy installation – book an appointment with approved installers
              </li>
              <li>
                Advanced charging tech – we’re working with smart charging experts
              </li>
            </ul>
            <a href='https://www.indra.co.uk/electrix' className='d-none d-md-block text-start mt-0 mb-5 w-75 cta cta--blue-outline'>
              <span className="d-xl-none">Discover chargers</span>
              <span className="d-md-none d-xl-block">Discover Indra smart chargers</span>
              <span className="icon icon__arrow--right">
                <svg focusable="false" width="16" height="9" viewBox="0 0 16 9" data-di-res-id="169be71e-29252940" data-di-rand="1656653705872">
                    <path d="M8,8.4 15.4,1.5 14.5,0.4 8,5.3 1.5,0.4 0.6,1.5" stroke="" fill="#006CF4"></path>
                </svg>
              </span>
            </a>
          </div>
          <div className="d-none d-md-block">
            <img src="/account-img/before-you-charge-it.png" alt="Electric Vehicle Charger"/>
          </div>
          <a href='https://www.indra.co.uk/electrix' className='d-md-none text-start mt-0 mb-5 w-100 cta cta--blue-outline'>
            <span className="d-sm-none">Discover chargers</span>
            <span className="d-none d-sm-block">Discover Indra smart chargers</span>
            <span className="icon icon__arrow--right">
              <svg focusable="false" width="16" height="9" viewBox="0 0 16 9" data-di-res-id="169be71e-29252940" data-di-rand="1656653705872">
                <path d="M8,8.4 15.4,1.5 14.5,0.4 8,5.3 1.5,0.4 0.6,1.5" stroke="" fill="#006CF4"></path>
              </svg>
            </span>
          </a>
        </div>
      </section>
    </>
  )
}

export default Charger;