import React from 'react';
import SiteMap from "./SiteMap";
import {useTitle} from "../../helpers/PageHeadHandler"

export const Footer = ({hrefIn, name}: {hrefIn?: string, name?: string}) => {
  useTitle(name)
  return (
    <>
      {/* DESKTOP VIEW */}
      <section className="d-none d-md-block px-4 container-lg">
        <SiteMap name={name} breadcrumbHrefIn={hrefIn} />
        <hr className="my-0 bg-dusty-mint"/>

        <div className="row d-flex mt-4">
          <div className="col-md-4">
            <h3 className="fw-bold">Electric cars</h3>
            <ul className="list-unstyled">
              <li><a href="https://www.lvelectrix.co.uk/">ElectriX homepage</a></li>
              <li><a href="https://www.lvelectrix.co.uk/basics">Basics</a></li>
              <li><a href="https://www.lvelectrix.co.uk/charging">Charging</a></li>
              <li><a href="https://www.lvelectrix.co.uk/costs">Costs</a></li>
              <li><a href="https://www.lvelectrix.co.uk/go-electric">Go electric</a></li>
            </ul>
          </div>

          <div className="col-md-4">
            <h3 className="fw-bold">About ElectriX</h3>
            <ul className="list-unstyled">
              <li><a href="https://www.lvelectrix.co.uk/about-us">About us</a></li>
              <li><a href="https://www.lvelectrix.co.uk/blog">Blog</a></li>
            </ul>
          </div>

          <div className="text-end col-md-4">
            <a href="https://www.facebook.com/LVElectriX" className="me-4">
              <img src="/account-img/facebook.svg" alt="Facebook"/>
            </a>
            <a href="https://www.linkedin.com/company/lvelectrix" className="me-4">
              <img src="/account-img/linkedin.svg" width="25" alt="Linkedin"/>
            </a>
            <a href="https://www.twitter.com/LVElectriX">
              <img src="/account-img/twitter.svg" alt="Twitter"/>
            </a>
          </div>
        </div>

        <hr className="my-0 bg-dusty-mint"/>

        <div className="d-flex justify-content-between my-3">
          <a href="https://www.lvelectrix.co.uk/terms">Terms</a>
          <a href="https://www.lvelectrix.co.uk/accessibility">Accessibility</a>
          <a href="https://www.lvelectrix.co.uk/privacy-notice">Privacy</a>
          <a href="https://www.lvelectrix.co.uk/cookie-policy">Cookies</a>
          <a href="https://www.lvelectrix.co.uk/sitemap">Sitemap</a>
        </div>

        <hr className="my-0 bg-dusty-mint"/>

        <div className="d-flex align-items-center mt-4 mb-5">
          <p className="mb-0 pt-0 me-4">
            <img src="/account-img/powered-by-lv-desktop-white.png" className="footer__logo" alt="LV= Logo"/>
          </p>
          <p className="mb-0 p-0 footer__text">
            ElectriX is a trading name of Buddies Enterprises Ltd and insurance cover is arranged and
            underwritten by Liverpool Victoria Insurance Company Limited (LVIC),
            both of which are part of the Liverpool Victoria General Insurance Group of companies.
            ElectriX is not a lender and introduce customers to CBVC Vehicle Management Ltd,
            who are a credit broker that use a panel of lenders.
            ElectriX will receive a commission for introductions that result in credit agreements being entered into.
            Buddies Enterprises Limited registered in England and Wales number 4013396 is authorised and
            regulated by the Financial Conduct Authority, register number 514428 and LVIC registered in England and
            Wales number 3232514 is authorised by the Prudential Regulation Authority and regulated by the Financial
            Conduct Authority and the Prudential Regulation Authority, register number 202965.
            Both companies registered address is: 57 Ladymead, Guildford, Surrey, GU1 1DB. Tel. 0330 1239970.
          </p>
        </div>
      </section>

      {/* MOBILE-TABLET VIEW */}
      <section className="d-md-none pb-5 px-4 container-lg">
        <div id="electricCars">
          <h2 id="electricCarsHeading">
            <button className="collapse-button collapsed" type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#electricCarsText"
                    aria-expanded="false"
                    aria-controls="electricCarsText">
              <span>Electric cars</span>
            </button>
          </h2>
          <div id="electricCarsText" className="collapse" aria-labelledby="electricCarsHeading" data-bs-parent="#electricCars">
            <div className="py-1">
              <ul className="list-unstyled small">
                <li><a href="https://www.lvelectrix.co.uk/">ElectriX homepage</a></li>
                <li><a href="https://www.lvelectrix.co.uk/basics">Basics</a></li>
                <li><a href="https://www.lvelectrix.co.uk/charging">Charging</a></li>
                <li><a href="https://www.lvelectrix.co.uk/costs">Costs</a></li>
                <li><a href="https://www.lvelectrix.co.uk/go-electric">Go electric</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div id="aboutElectrix">
          <h2 id="aboutElectrixHeading">
            <button className="collapse-button collapsed" type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#aboutElectrixText"
                    aria-expanded="false"
                    aria-controls="aboutElectrixText">
              <span>About Electrix</span>
            </button>
          </h2>
          <div id="aboutElectrixText" className="collapse" aria-labelledby="aboutElectrixHeading" data-bs-parent="#aboutElectrix">
            <div className="py-1">
              <ul className="list-unstyled small">
                <li><a href="https://www.lvelectrix.co.uk/about-us">About us</a></li>
                <li><a href="https://www.lvelectrix.co.uk/blog">Blog</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div id="legalTerms">
          <h2 id="legalTermsHeading">
            <button className="collapse-button collapsed" type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#legalTermsText"
                    aria-expanded="false"
                    aria-controls="legalTermsText">
              <span>Legal</span>
            </button>
          </h2>
          <div id="legalTermsText" className="collapse" aria-labelledby="legalTermsHeading" data-bs-parent="#legalTerms">
            <div className="py-1">
              <ul className="list-unstyled small">
                <li><a href="https://www.lvelectrix.co.uk/terms">Terms</a></li>
                <li><a href="https://www.lvelectrix.co.uk/accessibility">Accessibility</a></li>
                <li><a href="https://www.lvelectrix.co.uk/privacy-notice">Privacy</a></li>
                <li><a href="https://www.lvelectrix.co.uk/cookie-policy">Cookies</a></li>
                <li><a href="https://www.lvelectrix.co.uk/sitemap">Sitemap</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="d-flex mt-3 mb-5">
          <a href="https://www.facebook.com/LVElectriX" className="me-4">
            <img src="/account-img/facebook.svg" alt="Facebook"/>
          </a>
          <a href="https://www.linkedin.com/company/lvelectrix" className="me-4">
            <img src="/account-img/linkedin.svg" width="25" alt="Linkedin"/>
          </a>
          <a href="https://www.twitter.com/LVElectriX" className="me-4">
            <img src="/account-img/twitter.svg" alt="Twitter"/>
          </a>
        </div>

        <div>
          <img src="/account-img/powered-by-lv-desktop-white.png" className="footer__logo" alt="LV= Logo"/>
          <p className="mb-0 footer__text">
            ElectriX is a trading name of Buddies Enterprises Ltd and insurance cover is arranged and
            underwritten by Liverpool Victoria Insurance Company Limited (LVIC),
            both of which are part of the Liverpool Victoria General Insurance Group of companies.
            ElectriX is not a lender and introduce customers to CBVC Vehicle Management Ltd,
            who are a credit broker that use a panel of lenders.
            ElectriX will receive a commission for introductions that result in credit agreements being entered into.
            Buddies Enterprises Limited registered in England and Wales number 4013396 is authorised and
            regulated by the Financial Conduct Authority, register number 514428 and LVIC registered in England and
            Wales number 3232514 is authorised by the Prudential Regulation Authority and regulated by the Financial
            Conduct Authority and the Prudential Regulation Authority, register number 202965.
            Both companies registered address is: 57 Ladymead, Guildford, Surrey, GU1 1DB. Tel. 0330 1239970.
          </p>
        </div>
      </section>
    </>
  )
}