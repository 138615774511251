import React, {useEffect, useRef, useState} from 'react';
import NavBar from "./tco/NavBar";
import Vehicles from "./tco/Vehicles";
import Charger from "./tco/Charger";
import {ComparisonModal} from "./tco/ComparisonModal";
import {Footer} from "./tco/Footer";
import {Link, useParams} from "react-router-dom";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../store/Hooks";
import {
  fetchOffersThunk,
  selectAllOffers,
  selectFetchOffersStatus,
  selectOfferByLinkedOfferId
} from "../slices/OfferSlice";
import {ThunkStatus} from "../store/State";
import {MarketingChannel} from "../model/MarketingChannel";
import TagManager from "react-gtm-module";
import {useAddOrEditMetaTagWithAttributeValues, useEditMetaTagContentByName} from "../helpers/PageHeadHandler";
import {getVehicleImageSrc} from "../helpers/Helper";

export const SingleCarResult = () => {
  const id = useParams().id as string
  const linkedOfferId = parseInt(id)
  const dispatch = useAppDispatch()
  const offersStatus = useAppSelector(state => selectFetchOffersStatus(state))
  const offers = useAppSelector(state => selectAllOffers(state))
  const offer = useAppSelector(state => selectOfferByLinkedOfferId(state, linkedOfferId))

  useEditMetaTagContentByName('description', "See what it costs to lease an " + offer?.evOffer.vehicle.manufacturer +
      " " + offer?.evOffer.vehicle.model + " electric car including insurance, tax, fuel and maintenance.")
  useAddOrEditMetaTagWithAttributeValues("og:title", "See what it costs to lease an " + offer?.evOffer.vehicle.manufacturer +
      " " + offer?.evOffer.vehicle.model + " electric car | ElectriX")
  useAddOrEditMetaTagWithAttributeValues("og:url", window.location.href)

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (offer !== undefined) {
      if (isInitialMount.current) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'TCO - pageview car',
            make_TCO: offer?.evOffer.vehicle.manufacturer,
            model_TCO: offer?.evOffer.vehicle.model,
            monthly_rental_TCO: offer?.evOffer.leaseMonthlyCost,
            monthly_insurance_TCO: offer?.evOffer.insuranceMonthlyCost
          }
        })

        isInitialMount.current = false
      }
    }
  })

  const compareThisDataLayer = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'compare this'
      }
    })
  }

  const leaseThisDataLayer = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'lease this'
      }
    })
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let currentMonth = new Date().getMonth() + 1
  let currentYear = new Date().getFullYear()

  useEffect(() => {
    if (offersStatus === ThunkStatus.idle) {
      dispatch(fetchOffersThunk())
    }
  }, [offersStatus, dispatch, offer])

  let titleName = offer?.evOffer.vehicle.manufacturer.concat(' ').concat(offer?.evOffer.vehicle.model)

  return (
    <>
      {offer &&
      <>
        <header>
          <NavBar/>
          <div>
            <div className="px-4 py-2 container-lg col-lg-7">

              <div className="d-flex flex-column align-items-center pb-3">
                <h1 className="d-md-none fs-1 text-center mb-3 mt-2 text-electrix-blue">
                  {offer.evOffer.vehicle.manufacturer} {offer.evOffer.vehicle.model}
                </h1>
                <div className='text-center'>
                  <img src={getVehicleImageSrc(offer.evOffer.vehicle)} className="hero-car" alt="Electric Vehicle"/>
                </div>
                <h1 className="d-none d-md-block fs-1 text-center mt-5 text-electrix-blue">
                  {offer.evOffer.vehicle.manufacturer} {offer.evOffer.vehicle.model}
                </h1>
                <p className='fs-6 fs-md-5 text-center mb-0 text-electrix-blue'>
                  {offer.evOffer.vehicle.derivative}
                </p>
              </div>

              <div className="d-none d-md-block">
                <div id="carSpecificationDesktop">
                  <h2 id="carSpecificationDesktopHeading">
                    <button className="collapse-button collapsed" type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#carSpecificationDesktopText"
                            aria-expanded="false"
                            aria-controls="carSpecificationDesktopText">
                      <span className="fw-bold ps-4">Car specification</span>
                    </button>
                  </h2>
                  <div id="carSpecificationDesktopText" className="bg-cool-grey collapse"
                       aria-labelledby="carSpecificationDesktopHeading" data-bs-parent="#carSpecificationDesktop">
                    <p className="px-4 collapse-button__content">
                      Doors: {offer.evOffer.vehicle.doors} <br/>
                      <OverlayTrigger
                        trigger="hover"
                        placement="bottom"
                        overlay={
                          <Popover id="batteryRangePopoverDesktop">
                            <Popover.Header as="h3">&nbsp;</Popover.Header>
                            <Popover.Body>
                              Source: Carweb 2022
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <span>Battery Range ("WLTP range"): {offer.evOffer.vehicle.batteryRange.toLocaleString()} miles</span>
                      </OverlayTrigger>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-md-none">
              <div id="carSpecificationMobile">
                <h2 id="carSpecificationMobileHeading">
                  <button className="px-4 border-bottom-0 collapse-button collapsed" type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#carSpecificationMobileText"
                          aria-expanded="false"
                          aria-controls="carSpecificationMobileText">
                    <span className="fw-bold">Car specification</span>
                  </button>
                </h2>
                <div id="carSpecificationMobileText" className="px-4 bg-cool-grey collapse"
                     aria-labelledby="carSpecificationMobileHeading" data-bs-parent="#carSpecificationMobile">
                  <p className="collapse-button__content">
                    Doors: {offer.evOffer.vehicle.doors} <br/>
                    <OverlayTrigger
                      trigger="hover"
                      placement="bottom"
                      overlay={
                        <Popover id="batteryRangePopoverMobile">
                          <Popover.Header as="h3">&nbsp;</Popover.Header>
                          <Popover.Body>
                            Source: Carweb 2022
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <span>Battery Range ("WLTP range"): {offer.evOffer.vehicle.batteryRange.toLocaleString()} miles</span>
                    </OverlayTrigger>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </header>

        {offer !== undefined &&
          <section className="p-4 position-relative bg-dusty-mint" id="leaseAndMileage">
            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center text-center gap-md-6">
              <div className="pt-2 pt-md-0">
                <p className="mb-3 ms-md-5 mb-md-0">
                  <span className="fw-bold">Length of the lease</span><br/>
                  { offers.term } months
                </p>
              </div>
              <div className="pb-2 pb-md-0">
                <p className="mb-0">
                  <span className="fw-bold">Average annual mileage</span><br/>
                  { offers.mileage.toLocaleString() } miles
                </p>
              </div>
            </div>
          </section>
        }

        <section className="d-flex flex-column px-4 py-2 container-lg col-lg-7">
          <div className="text-center mt-4 mt-lg-5 mb-lg-2">
            <p className="fw-bold fs-4 mb-2 ff-livvic">Estimated total monthly costs</p>
            <p className="fw-bold fs-1 pb-2 ff-livvic text-electrix-blue">&#163;{ offer.evOffer.estimatedMonthlyCost }</p>
          </div>
          <div className="text-center p-2 px-lg-4 mb-4 mx-lg-4 bg-cool-grey">
            <p className="fs-6 p-0 m-0">
                <strong>Costs shown are indicative based on market averages and may differ
                    based on your personal circumstances.
                </strong>
            </p>
          </div>
          <div className="row d-flex flex-column flex-md-row pt-3 px-md-5 px-lg-4">
            <div className="text-center text-md-start pb-2 col-md-4">
              <hr className="my-1 d-md-none"/>
              <div className="position-relative">
                <h3 className="fw-bold mb-0">
                  Car lease
                  <OverlayTrigger
                    trigger="focus"
                    placement="bottom"
                    overlay={
                      <Popover id="carLeasePopover">
                        <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                        <Popover.Body>
                          Based on a personal contract hire lease for the given term and mileage from a panel of five lenders.
                          Excludes initial rental fee and processing fees.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button className="position-absolute top-50 end-0 translate-middle-y info-icon" variant="link"
                            onClick={(e) => e.currentTarget.focus()}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 122.8 123.3">
                        <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10" strokeMiterlimit="10" cx="61.4" cy="61.6"
                                 rx="56.4" ry="56.6"/>
                        <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                        <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                      </svg>
                    </Button>
                  </OverlayTrigger>
                </h3>
              </div>
              <hr className="d-none d-md-block my-md-1"/>
              <p>&#163;{ offer.evOffer.leaseMonthlyCost }</p>
            </div>
            <div className="text-center text-md-start pb-2 col-md-4">
              <hr className="my-1 d-md-none"/>
              <div className="position-relative">
                <h3 className="fw-bold mb-0">
                  Electricity / Fuel
                  <OverlayTrigger
                    trigger="focus"
                    placement="bottom"
                    overlay={
                      <Popover id="electricityFuelPopover">
                        <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                        <Popover.Body>
                          Electricity costs assume that vehicle is charged overnight between 10pm and 8am on a national average rate of
                          &#163;{offers.currentElectricityPrice} and annual mileage given and vehicle kWh/mile.
                          Petrol and diesel costs are based on national average fuels rate of &#163;{offers.currentPetrolPrice} and &#163;{offers.currentDieselPrice} per litre.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button className="position-absolute top-50 end-0 translate-middle-y info-icon" variant="link"
                            onClick={(e) => e.currentTarget.focus()}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 122.8 123.3">
                        <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10" strokeMiterlimit="10" cx="61.4" cy="61.6"
                                 rx="56.4" ry="56.6"/>
                        <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                        <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                      </svg>
                    </Button>
                  </OverlayTrigger>
                </h3>
              </div>
              <hr className="d-none d-md-block my-md-1"/>
              <p>&#163;{offer.evOffer.fuelMonthlyCost}</p>
            </div>
            <div className="text-center text-md-start pb-2 col-md-4">
              <hr className="my-1 d-md-none"/>
              <div className="position-relative">
                <h3 className="fw-bold mb-0">
                  Car tax
                  <OverlayTrigger
                    trigger="focus"
                    placement="bottom"
                    overlay={
                      <Popover id="carTaxMonthlyCostPopover">
                        <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                        <Popover.Body>
                          Car tax based on vehicle excise duty from gov.uk
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button className="position-absolute top-50 end-0 translate-middle-y info-icon" variant="link"
                            onClick={(e) => e.currentTarget.focus()}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 122.8 123.3">
                        <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10" strokeMiterlimit="10" cx="61.4" cy="61.6"
                                 rx="56.4" ry="56.6"/>
                        <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                        <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                      </svg>
                    </Button>
                  </OverlayTrigger>
                </h3>
              </div>
              <hr className="d-none d-md-block my-md-1"/>
              <p>&#163;{offer.evOffer.carTaxMonthlyCost}</p>
            </div>
            <div className="text-center text-md-start pb-2 col">
              <hr className="my-1 d-md-none"/>
              <div className="position-relative">
                <h3 className="fw-bold mb-0">
                  Maintenance
                  <OverlayTrigger
                    trigger="focus"
                    placement="bottom"
                    overlay={
                      <Popover id="maintenancePopover">
                        <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                        <Popover.Body>
                          Based on CBVC data {currentMonth} {currentYear}. Includes all routine servicing, tyres and breakdown cover.
                          Subject to normal wear and tear.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button className="position-absolute top-50 end-0 translate-middle-y info-icon" variant="link"
                            onClick={(e) => e.currentTarget.focus()}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 122.8 123.3">
                        <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10" strokeMiterlimit="10" cx="61.4" cy="61.6"
                                 rx="56.4" ry="56.6"/>
                        <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                        <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                      </svg>
                    </Button>
                  </OverlayTrigger>
                </h3>
              </div>
              <hr className="d-none d-md-block my-md-1"/>
              <p>&#163;{offer.evOffer.maintenanceCost}</p>
            </div>
            <div className="text-center text-md-start pb-2 col">
              <hr className="my-1 d-md-none"/>
              <div className="position-relative">
                <h3 className="fw-bold mb-0">
                  Insurance
                  <OverlayTrigger
                    trigger="focus"
                    placement="bottom"
                    overlay={
                      <Popover id="insurancePopover">
                        <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                        <Popover.Body>
                          Estimations are based on data provided by Allianz Insurance using their recent quote
                          and sales information for this vehicle.<br/><br/>

                          In order to generate this indicative price the following parameters were used.
                          A minimum sample size of at least 100 quotations or sales,
                          generated over the 3 months of Jan 2023 to March 2023 and spanning a variety of insurable risks.
                          Optional Add-ons were not included.<br/><br/>

                          NB: Insurance is based on individual circumstances and any prices or eligibility are specific to each driver.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button className="position-absolute top-50 end-0 translate-middle-y info-icon" variant="link"
                            onClick={(e) => e.currentTarget.focus()}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 122.8 123.3">
                        <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10" strokeMiterlimit="10" cx="61.4" cy="61.6"
                                 rx="56.4" ry="56.6"/>
                        <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                        <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                      </svg>
                    </Button>
                  </OverlayTrigger>
                </h3>
              </div>
              <hr className="d-none d-md-block my-md-1"/>
              <p>&#163;{offer.evOffer.insuranceMonthlyCost}</p>
            </div>
          <div className="border-dusty-mint mb-4 text-center ps-0 pe-0">
            <div className="row border-mx-n1">
              <div className="text-md-start pb-2 pt-2 col-12">
                <div className="position-relative">
                  <h2 className="fw-bold mb-0 pt-1 costs text-center">
                    Upfront costs
                  </h2>
                  <h3 className="fw-bold mt-1 mb-0 pb-1 text-center">
                    (not included in the monthly estimates)
                  </h3>
                </div>
              </div>
              <div className="text-center text-md-start pb-2 col-md-6">
                <hr className="my-1 d-md-none"/>
                <div className="position-relative">
                  <h3 className="fw-bold mb-0">
                    Home charger
                    <OverlayTrigger
                        trigger="focus"
                        placement="bottom"
                        overlay={
                          <Popover id="homeChargerPopover">
                            <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                            <Popover.Body>
                              Based on an ElectriX Indra charger with standard installation
                              at &#163;{offers.homeChargerCost} inc VAT.
                              Final costs may differ based on your circumstances.
                            </Popover.Body>
                          </Popover>
                        }
                    >
                      <Button className="position-absolute top-50 end-0 translate-middle-y info-icon" variant="link"
                              onClick={(e) => e.currentTarget.focus()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 122.8 123.3">
                          <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10" strokeMiterlimit="10" cx="61.4"
                                   cy="61.6"
                                   rx="56.4" ry="56.6"/>
                          <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                          <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                        </svg>
                      </Button>
                    </OverlayTrigger>
                  </h3>
                </div>
                <hr className="d-none d-md-block my-md-1"/>
                <p>&#163;{offers.homeChargerCost}</p>
              </div>

              <div className="text-center text-md-start pb-2 col-md-6">
                <hr className="my-1 d-md-none"/>
                <div className="position-relative">
                  <h3 className="fw-bold mb-0">
                    Initial rental
                    <OverlayTrigger
                        trigger="focus"
                        placement="bottom"
                        overlay={
                          <Popover id="homeChargerPopover">
                            <Popover.Header className='close-icon' as="h3">&nbsp;</Popover.Header>
                            <Popover.Body>
                              Your initial rental is the first payment that you make towards the lease of your new vehicle,
                              calculated as a multiple of monthly rental payments. The value shown here is 12 months, but
                              can be calculated as 1, 3, 6, 9 or 12, depending on your preference.
                            </Popover.Body>
                          </Popover>
                        }
                    >
                      <Button className="position-absolute top-50 end-0 translate-middle-y info-icon" variant="link"
                              onClick={(e) => e.currentTarget.focus()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 122.8 123.3">
                          <ellipse fill="none" stroke="#3F6BB4" strokeWidth="10" strokeMiterlimit="10" cx="61.4"
                                   cy="61.6"
                                   rx="56.4" ry="56.6"/>
                          <rect x="55.8" y="47.2" fill="#3F6BB4" width="11.3" height="58.5"/>
                          <ellipse fill="#3F6BB4" cx="61.6" cy="28.6" rx="7.3" ry="7.1"/>
                        </svg>
                      </Button>
                    </OverlayTrigger>
                  </h3>
                </div>
                <hr className="d-none d-md-block my-md-1"/>
                <p>&#163;{offer.evOffer.initialRentalCost}</p>
              </div>
            </div>
          </div>
          </div>
          <div className="d-none d-md-block">
            <div id="viewTheseCostsInDetailTablet">
              <h2 id="viewTheseCostsInDetailTabletHeading">
                <button className="collapse-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#viewTheseCostsInDetailTabletText"
                        aria-expanded="false"
                        aria-controls="viewTheseCostsInDetailTabletText">
                  <span className="fw-bold ps-4">How do we calculate these costs?</span>
                </button>
              </h2>
              <div id="viewTheseCostsInDetailTabletText" className="bg-cool-grey collapse"
                   aria-labelledby="viewTheseCostsInDetailTabletHeading" data-bs-parent="#viewTheseCostsInDetailTablet">
                <p className="px-4 collapse-button__content">
                  <span className="fw-bold ff-livvic">Car lease</span><br/>
                  This price doesn’t include the Initial Payment of &#163;{offer.evOffer.initialRentalCost}, and a Processing Fee
                  of &#163;{offer.evOffer.contractFee}.
                  All prices shown subject to change at any time and would be subject to finance approval and vehicle
                  availability.
                  Failure to maintain payments may result in termination of your agreement and the vehicle being returned,
                  this
                  could effect your credit rating and make it more difficult to obtain credit in the future. All prices
                  correct
                  at time of publication and maybe withdrawn without prior notice. Personal Lease prices include VAT.
                  When pursing a formal quote, you should try and estimate the distance you will travel as accurately as
                  possible to try and avoid excess mileage charges. Excess mileage charge will apply and will be detailed
                  in any formal quotation. Damage charges may apply.<br/><br/>
                  <span className="fw-bold ff-livvic">Insurance</span><br/>
                    Estimations are based on data provided by Allianz Insurance using their recent quote and sales information for this vehicle.<br/><br/>
                    In order to generate this indicative price the following parameters were used. A minimum sample size of at least 100 quotations or sales, generated over the 3 months of Jan 2023 to March 2023 and spanning a variety of insurable risks. Optional Add-ons were not included.<br/><br/>
                    NB: Insurance is based on individual circumstances and any prices or eligibility are specific to each driver.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="d-md-none px-0 container-lg col-lg-7">
          <div id="viewTheseCostsInDetailMobile">
            <h2 id="viewTheseCostsInDetailMobileHeading">
              <button className="px-4 collapse-button collapsed" type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#viewTheseCostsInDetailMobileText"
                      aria-expanded="false"
                      aria-controls="viewTheseCostsInDetailMobileText">
                <span className="fw-bold">How do we calculate these costs?</span>
              </button>
            </h2>
            <div id="viewTheseCostsInDetailMobileText" className="bg-cool-grey collapse"
                 aria-labelledby="viewTheseCostsInDetailMobileHeading" data-bs-parent="#viewTheseCostsInDetailMobile">
              <p className="px-4 collapse-button__content">
                <span className="fw-bold ff-livvic">Car lease</span><br/>
                This price doesn’t include the Initial Payment of %{offer.evOffer.initialRentalCost}, and a Processing Fee
                of &#163;{offer.evOffer.contractFee}.
                All prices shown subject to change at any time and would be subject to finance approval and vehicle
                availability.
                Failure to maintain payments may result in termination of your agreement and the vehicle being returned,
                this
                could effect your credit rating and make it more difficult to obtain credit in the future. All prices
                correct
                at time of publication and maybe withdrawn without prior notice. Personal Lease prices include VAT.
                When pursing a formal quote, you should try and estimate the distance you will travel as accurately as
                possible to try and avoid excess mileage charges. Excess mileage charge will apply and will be detailed
                in any formal quotation. Damage charges may apply.<br/><br/>
                <span className="fw-bold ff-livvic">Insurance</span><br/>
                Estimations are based on data provided by Allianz Insurance using their recent quote and sales information for this vehicle.<br/><br/>
                In order to generate this indicative price the following parameters were used. A minimum sample size of at least 100 quotations or sales, generated over the 3 months of Jan 2023 to March 2023 and spanning a variety of insurable risks. Optional Add-ons were not included.<br/><br/>
                NB: Insurance is based on individual circumstances and any prices or eligibility are specific to each driver.
              </p>
            </div>
          </div>
        </section>

        <section className="p-4 container-lg col-lg-7 justify-content-center">
          <div className="d-flex justify-content-center">
            <Link to={
              {
                pathname: '/preferences/signup',
                search: new URLSearchParams({marketingChannel: MarketingChannel.TCO, linkedOfferId: id}).toString()
              }
            } id={"emailCostsToMe-" + offer.id} className='d-block text-start m-0 w-100 w-md-auto cta cta--blue-outline'>
              <span className="me-4">Email costs to me</span>
              <span className="icon icon__arrow--right">
                <svg focusable="false" width="16" height="9" viewBox="0 0 16 9" data-di-res-id="169be71e-29252940" data-di-rand="1656653705872">
                  <path d="M8,8.4 15.4,1.5 14.5,0.4 8,5.3 1.5,0.4 0.6,1.5" stroke="" fill="#006CF4"></path>
                </svg>
              </span>
            </Link>
          </div>
        </section>
        <section className="px-4 header-theme ">
          <div className="d-flex flex-column-reverse py-5 flex-md-row-reverse justify-content-md-center">
            <a href={offer.evOffer.leasingLink} className='text-start mb-3 cta cta--green' onClick={leaseThisDataLayer}>
              <span>Lease this car</span>
              <span className="icon icon__arrow--right">
                <svg focusable="false" width="16" height="9" viewBox="0 0 16 9"
                     data-di-res-id="169be71e-29252940" data-di-rand="1656653705872">
                  <path d="M8,8.4 15.4,1.5 14.5,0.4 8,5.3 1.5,0.4 0.6,1.5" stroke="" fill="#003578"></path>
                </svg>
              </span>
            </a>
            {/*Modal usage removed temporary*/}
            {/*<button className='text-start mb-3 me-md-5 cta cta--white-outline' onClick={()=> handleShow()}>*/}
            {/*  <span className="me-md-5">Compare to a petrol car</span>*/}
            {/*  <span className="icon icon__arrow--right">*/}
            {/*    <svg focusable="false" width="16" height="9" viewBox="0 0 16 9"*/}
            {/*         data-di-res-id="169be71e-29252940" data-di-rand="1656653705872">*/}
            {/*      <path d="M8,8.4 15.4,1.5 14.5,0.4 8,5.3 1.5,0.4 0.6,1.5" stroke="" fill="#fff"></path>*/}
            {/*    </svg>*/}
            {/*  </span>*/}
            {/*</button>*/}
            <Link className='text-start mb-3 me-md-5 cta cta--white-outline'
                  to={'/comparison/' + id}
                  onClick={compareThisDataLayer}>
              <span className="me-md-5">Compare with a petrol/diesel car</span>
              <span className="icon icon__arrow--right">
                <svg focusable="false" width="16" height="9" viewBox="0 0 16 9"
                     data-di-res-id="169be71e-29252940" data-di-rand="1656653705872">
                  <path d="M8,8.4 15.4,1.5 14.5,0.4 8,5.3 1.5,0.4 0.6,1.5" stroke="" fill="#fff"></path>
                </svg>
              </span>
            </Link>
          </div>
          <ComparisonModal show={show} handleClose={handleClose} handleShow={handleShow}/>
        </section>

        <section className="py-5 px-4 bg-cool-grey">
          <div className="container-lg">
            <h2 className="fs-1 mb-5">
              View other cars
            </h2>
            <Vehicles offers={offers.offers}/>
          </div>
        </section>
      </> }

      <Charger/>

      <Footer hrefIn={"/single-car-result/" + id} name={titleName}/>
    </>
  )
}

export default SingleCarResult;
