import React from "react";
import {act, fireEvent, render} from "@testing-library/react";
import {BrowserRouter} from "react-router-dom";

export const mockFetch = (response?: object, status = 200, statusText?: string): void => {
    jest.spyOn(global, "fetch").mockImplementationOnce(jest.fn(() => Promise.resolve(
        {
            ok: 200 <= status && status <= 299,
            json: () => Promise.resolve(response),
            status: status,
            statusText: statusText ?? mapToStatusText(status)
        }),) as jest.Mock)
}

const mapToStatusText = (status?: number) => {
    if (500 === status) {
        return "Internal server error";
    }

    if (403 === status) {
        return "Forbidden"
    }

    return undefined;
}

export const renderWithRouter = (ui: React.ReactElement) => {
    return render(
        <BrowserRouter>
            {ui}
        </BrowserRouter>
    )
}

export const actWaitForRender = async () => {
    await act(async () => {
        /* Wait for render */
    })
}

export const actFillInput = async (field: HTMLElement, value: string) => {
    await act(async () => {
        fireEvent.change(field, {target: {value}})
    })
}

export const actClick = async (element: HTMLElement) => {
    await act(async () => {
        fireEvent.click(element)
    })
}