import React, {useEffect, useRef, useState} from 'react';
import NavBar from "./tco/NavBar";
import Vehicles from "./tco/Vehicles";
import Charger from "./tco/Charger";
import {Footer} from "./tco/Footer";
import {fetchOffersThunk, selectAllOffers, selectFetchOffersStatus} from "../slices/OfferSlice";
import {useAppDispatch, useAppSelector} from "../store/Hooks";
import {ThunkStatus} from "../store/State";
import {store} from "../store/Store";
import TagManager from "react-gtm-module";
import {
  useAddOrEditMetaTagWithAttributeValues,
  useEditMetaTagContentByName
} from "../helpers/PageHeadHandler";

export const VehicleGallery = () => {
  const dispatch = useAppDispatch()
  const offersStatus = useAppSelector(state => selectFetchOffersStatus(state))
  const offers = useAppSelector(state => selectAllOffers(state))
  const [loading, setLoading] = useState(false)

  useEditMetaTagContentByName('description', 'Our Car Comparison Tool allows you to see what it costs to ' +
      'lease ' + offers.offers.length + ' popular electric cars including insurance, tax, fuel and maintenance and compare ' +
      'different electric cars to traditional cars.')
  useAddOrEditMetaTagWithAttributeValues("og:title", "Discover Electric Cars | ElectriX")
  useAddOrEditMetaTagWithAttributeValues("og:url", window.location.href)

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'TCO - pageview car'
        }
      })

      isInitialMount.current = false
    }
  })

  useEffect(() => {
    if (offersStatus === ThunkStatus.idle) {
      store.dispatch(fetchOffersThunk())
      setLoading(true)
    }
    if (offersStatus === ThunkStatus.succeeded) {
      setLoading(false)
    }
  }, [offersStatus, dispatch, setLoading])

  return (
    <>
      <NavBar/>

      <header className='bg-electrix-gradient'>
        <div className="container-lg">
          <div
            className='row d-flex flex-column-reverse justify-content-between align-items-center px-4 pt-5 pb-4 pb-lg-5 flex-lg-row px-lg-1'>
            <div className="col-lg-4 col-xl-5 pe-0">
              <h1 className='text-start my-4 car-gallery-page-h1'>
                How much could an electric car<br/>
                save you?
              </h1>
              <p className='text-white'>
                How much will you pay each month for an electric car?<br/>
                We've done the sums to help you find out.<br/>
                Some people think electric cars are expensive - but running costs like charging, car tax, insurance and
                maintenance
                can make it work out cheaper than petrol or diesel.
              </p>
            </div>
            <div className="d-flex justify-content-center justify-content-lg-end col-lg-8 col-xl-7">
              <img src='/account-img/car-gallery-page-hero.png' className="w-100 car-gallery-page-hero" alt='Electric Vehicle'/>
            </div>
          </div>
        </div>
      </header>

      <section className="d-none py-4 d-md-block"></section>

      <section className="py-5 px-4 bg-cool-grey">
        <div className="container-lg">
          {loading ?
            <div className="d-flex justify-content-center">
              <div className="loader"></div>
            </div>
            :
            <Vehicles offers={offers.offers}/>
          }
        </div>
      </section>

      <Charger/>

      <Footer hrefIn="/vehicle-gallery" name="Compare costs"/>
    </>
  )
}

export default VehicleGallery;