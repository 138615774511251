import {throwErrorOnNotOk} from "../helpers/Helper";

export const fetchLeasingLink = async (vehicleVariantId: number) => {
  const response = await fetch('/api/leasing-links/'+ vehicleVariantId, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
  const json = await response.json()

  throwErrorOnNotOk(response)

  return json
}