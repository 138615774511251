import {CaseReducer} from "@reduxjs/toolkit/src/createReducer";

export enum ThunkStatus {
  idle,
  loading,
  succeeded,
  failed,
}

export interface State {
  status : { [key: string]: ThunkStatus }
  error? : string
}

export interface StateMandatory<T> extends State {
  data: T
}

export interface StateOptional<T> extends State {
  data?: T | null
}


export const pendingCaseReducer: CaseReducer<any, ReturnType<any>> = ((state, action) => {
  let suffix = action.meta.arg ? "/" + action.meta.arg : ""
  state.status[getActinStatePrefix(action) + suffix] = ThunkStatus.loading
})

export const rejectedCaseReducer: CaseReducer<any, ReturnType<any>> = ((state, action) => {
  let suffix = action.meta.arg ? "/" + action.meta.arg : ""
  state.status[getActinStatePrefix(action) + suffix] = ThunkStatus.failed
  state.error = action.error.message
})

export const fulfilledCaseReducer: CaseReducer<any, ReturnType<any>> = ((state, action) => {
  let suffix = action.meta.arg ? "/" + action.meta.arg : ""
  state.status[getActinStatePrefix(action) + suffix] = ThunkStatus.succeeded
  state.data = action.payload
})

export const getActinStatePrefix = (action: ReturnType<any>) => {
  return action.type
    .replace("/fulfilled", "")
    .replace("/rejected","")
    .replace("/fulfilled","")
}