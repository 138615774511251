import React, {useEffect} from "react";

export const ExternalRedirectPage = () => {
    useEffect(() => {
        window.location.replace('https://www.lvelectrix.co.uk/')
    }, [])
    return (
    <></>
  )
}

export default ExternalRedirectPage