export interface ValidationRule {
    regex: RegExp
    errorMessage: string
}

export const nameValidationRule: ValidationRule =
    {
        regex: /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u01FF]+([,]{0,1}[ \-'’`]{0,1}[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u01FF]+){0,2}([.]{0,1}[a-zA-Z]){0,1}$/,
        errorMessage: 'The field contains illegal symbols!',
    }

export const emailValidationRule: ValidationRule =
    {
        regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
        errorMessage: 'Please enter a valid email address!'
    }
