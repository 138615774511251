import {useEffect} from "react";

export function useTitle(title: any) {
    useEffect(() => {
        const prevTitle = document.title
        document.title = title
        return () => {
            document.title = prevTitle
        }
    })
}

export function useEditMetaTagContentByName(name: string, content: string) {
    useEffect(() => {
        let prevContentValue = ''

        const documentTagElements = document.getElementsByTagName('meta')
        const tagElementsArray = Array.prototype.slice.call(documentTagElements)
        tagElementsArray.forEach((e => {
            if (e.getAttribute("name") === name) {
                prevContentValue = e.getAttribute("content")
                e.setAttribute("content", content)
            }
        }))

        return () => {
            tagElementsArray.forEach((e => {
                if (e.getAttribute("name") === name) {
                    e.setAttribute("content", prevContentValue)
                }
            }))
        }
    })
}

export function useAddOrEditMetaTagWithAttributeValues(property: string, content: string) {
    useEffect(() => {
        const documentMetaTagElements = document.getElementsByTagName('meta')
        const metaTagElementsArray = Array.prototype.slice.call(documentMetaTagElements)

        let prevContentValue = ''
        metaTagElementsArray.forEach((metaTag => {
            if (metaTag.getAttribute("property") === property) {
                prevContentValue = metaTag.getAttribute("content")
                metaTag.setAttribute("content", content)
            }
        }))

        return () => {
            let isMetaTagPresent = false
            metaTagElementsArray.forEach((metaTag => {
                if (metaTag.getAttribute("property") === property) {
                    metaTag.setAttribute("content", prevContentValue)
                    isMetaTagPresent = true
                }
            }))

            if (!isMetaTagPresent) {
                const headTag = document.getElementsByTagName('head')[0]
                const metaTag = document.createElement("meta")
                metaTag.setAttribute("property", property)
                metaTag.setAttribute("content", content)
                headTag.appendChild(metaTag)
            }
        }
    })
}