import React from 'react';
import {Link} from 'react-router-dom'
import {LinkedOffer} from "../../model/LinkedOffer";
import {getVehicleImageSrc} from "../../helpers/Helper";

interface IProps {
  offers: LinkedOffer[]
}

export const Vehicles = (props: IProps) => {
  let offers = props.offers.map(offer => {
    return (
      <div key={offer.evOffer.id} className="monthly-costs-card d-flex flex-column mb-5 px-0">
        <div className="monthly-costs-card__upper">
          <h3 className="text-electrix-blue fw-bold fs-5 mb-0">
            {offer.evOffer.vehicle.manufacturer} {offer.evOffer.vehicle.model}
          </h3>
        </div>
        <div className="monthly-costs-card__lower">
          <div className="d-flex justify-content-center align-items-center position-relative">
            <img src={getVehicleImageSrc(offer.evOffer.vehicle)} className="position-absolute bottom-0" width="232px" alt="Car"/>
          </div>
          <p className="pt-0 mb-4">
            <span className="fs-6 fw-bold">Estimated monthly cost</span><br/>
            <span
              className="fs-4 fw-bold ff-livvic text-electrix-blue">&#163;{offer.evOffer.estimatedMonthlyCost}</span>
          </p>

          <Link to={'/single-car-result/' + offer.id}
                id={"viewCostDetails-" + offer.id}
                className='d-block text-start fs-6 w-100 cta cta--sm cta--blue-outline'>
            <span>View cost details</span>
            <span className="icon icon__arrow--right">
                <svg focusable="false" width="16" height="9" viewBox="0 0 16 9"
                     data-di-res-id="169be71e-29252940" data-di-rand="1656653705872">
                  <path d="M8,8.4 15.4,1.5 14.5,0.4 8,5.3 1.5,0.4 0.6,1.5" stroke="" fill="#006CF4"></path>
                </svg>
              </span>
          </Link>
        </div>
      </div>
    )
  })

  let visibleOffers = offers.slice(0, 8)
  let collapsedOffers = offers.slice(8)
  let emptyCardsForVisibleOffers
  let emptyCardsForCollapsedOffers
  if (visibleOffers.length % 4 !== 0) emptyCardsForVisibleOffers = Array(4 - visibleOffers.length % 4).fill(undefined)
  if (collapsedOffers.length % 4 !== 0) emptyCardsForCollapsedOffers = Array(4 - collapsedOffers.length % 4).fill(undefined)
  return (
    <>
      <div className="row mx-0 d-flex flex-column justify-content-between flex-md-row">
        {visibleOffers}
        {emptyCardsForVisibleOffers?.map((emptyCard, index) => {
          return (
            <div key={index} className="monthly-costs-card blank-card">
            </div>
          )
        })}
        <div id="viewMoreCars" className="collapse">
          <div className="row d-flex flex-column justify-content-between px-0 flex-md-row">
            {collapsedOffers}
            {emptyCardsForCollapsedOffers?.map((emptyCard, index) => {
              return (
                <div key={index} className="monthly-costs-card blank-card">
                </div>
              )
            })}
          </div>
        </div>
      </div>

      {collapsedOffers.length > 0 &&
          <div className="d-flex justify-content-center">
              <a href="#viewMoreCars" className="position-relative collapse-link collapsed" data-bs-toggle="collapse"
                 role="button" aria-expanded="false" aria-controls="viewMoreCars">
                  <span className="me-2">View more cars</span>
              </a>
          </div>
      }
    </>
  )
}

export default Vehicles;
