import UpdatePreferences from './UpdatePreferences';
import {useEffect, useState} from 'react';
import Registration from "./Registration";
import {useParams, useSearchParams} from "react-router-dom";
import {notEmpty} from "../../helpers/Helper";
import {MarketingChannel} from "../../model/MarketingChannel";
import {fetchChannelConfig} from "../../services/SubscriptionService";
import {MarketingFormConfiguration} from "@electrix/shared";

export const Preferences = () => {
    const [preference, setPreference] = useState('NONE');
    const [searchParams] = useSearchParams()

    const chanelIdParam = searchParams.get('cid')
    const marketingChannelParam = chanelIdParam ? atob(chanelIdParam) : searchParams.get('marketingChannel')
    const linkedOfferIdParam = searchParams.get("linkedOfferId")
    const pageParam = useParams().page as string

    const marketingChannel = marketingChannelParam == null ? MarketingChannel.ULTIMATE_GUIDE : marketingChannelParam;
    const page = pageParam == null ? "signup" : pageParam;
    const linkedOfferId = parseInt(notEmpty({parameter: linkedOfferIdParam}))

    const [channelConfig, setChannelConfig] = useState<MarketingFormConfiguration>()
    const pageConfig = channelConfig && channelConfig.pages.find(x => x.id === page)

    useEffect(() => {
        fetchChannelConfig(marketingChannel).then(c => setChannelConfig(c))
    }, [marketingChannel, setChannelConfig])

    if (!pageConfig) {
        return <>
            <div className="preferences-loader">
                <div className="content">
                    <div className="ov-spinner">
                        <div className="bounce1"/>
                        <div className="bounce2"/>
                        <div className="bounce3"/>
                    </div>
                </div>
            </div>
        </>
    }
    return (
        <>
            {channelConfig && (
                <>
                    {preference === 'NONE' &&
                        <UpdatePreferences
                            page={page}
                            onPreferenceChange={setPreference}
                            pageConfig={pageConfig}
                            marketingChannel={marketingChannel}
                            linkedOfferId={linkedOfferId}/>}
                    {preference === 'EMAIL' &&
                        <Registration pageConfig={pageConfig} marketingChannel={marketingChannel}/>}
                </>
            )}
        </>
    )
}

export default Preferences
