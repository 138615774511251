export enum UnsubscriptionReason {
    I_DONT_WANT_TO_GIVE_A_REASON = "I_DONT_WANT_TO_GIVE_A_REASON",
    CONTENT_NOT_RELEVANT = "CONTENT_NOT_RELEVANT",
    TOO_MANY_EMAILS = "TOO_MANY_EMAILS",
    I_DONT_REMEMBER_SIGNING_UP = "I_DONT_REMEMBER_SIGNING_UP",
    OTHER = "OTHER"
}

export interface Unsubscription {
    comment: string
    reason : UnsubscriptionReason
}
