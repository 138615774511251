import React from 'react';

export const Header = () => {
  return (
    <>
      <div className="subscription-header">
        <div className="d-flex align-items-center px-0 container-lg">
          <div className="px-3 px-md-3 py-2 mb-1">
            <img src='/account-img/electrix-white-logo.svg' className="py-2 banner__logo" alt="ElectriX Logo"/>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header;
