import React from "react";
import Footer from "./Footer";
import {Page} from "@electrix/shared";
import {Html} from "./Html";
import Header from "./Header";

interface IProps {
    pageConfig: Page,
    marketingChannel: string
}


export const Registration = (props: IProps) => {

    return (
        <>
            <Header/>
            <header className='bg-electrix-gradient mb-5 pb-5'>
                <div className="container-lg">
                    <div
                        className='row d-flex flex-column-reverse justify-content-between px-4 pt-5 pb-4 pb-lg-5 flex-lg-row px-lg-1'>
                        <div className="mt-5 col-lg-4 col-xl-5 pe-0 preferences-greeting">
                            <h1 className='me-5 mb-0 qa-subscription-success'>
                                {props.pageConfig.signOff.title}
                            </h1>
                            <p className='text-white preferences-signoff-content'>
                                <Html html={props.pageConfig.signOff.content}/>
                            </p>
                            {props.pageConfig.buttons.signOff && (
                                <a href={props.pageConfig.buttons.signOff.navigateTo} className='text-start mb-3 cta cta--green'>
                                    <span className="text">{props.pageConfig.buttons.signOff.label || "Read more Insight"}</span>
                                    <span className="icon icon__arrow--right">
                                <svg focusable="false" width="16" height="9" viewBox="0 0 16 9"
                                     data-di-res-id="169be71e-29252940" data-di-rand="1656653705872">
                                  <path d="M8,8.4 15.4,1.5 14.5,0.4 8,5.3 1.5,0.4 0.6,1.5" stroke="" fill="#003578"></path>
                                </svg>
                                </span>
                                </a>
                            )}
                        </div>
                        <div className="d-flex justify-content-center justify-content-lg-end col-lg-8 col-xl-7 mb-5">
                            {props.pageConfig.greetingImage && (
                                <img src={props.pageConfig.greetingImage.content} className="w-100 car-gallery-page-hero" alt='Electric Vehicle'/>
                            )}
                        </div>
                    </div>
                </div>
            </header>
            <Footer/>
        </>
    )
}

export default Registration
