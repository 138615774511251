import React, {useEffect, useState} from 'react';

interface IProps<T> {
    action: () => Promise<T>
    success?: (data: T) => void
}

export const ServiceActionButton : React.FunctionComponent<IProps<any>> = props => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (loading) {
            props.action().then(response => {
                setLoading(false)
                props.success && props.success(response)
            }).catch(() => {
                setError(true)
            })
        }
    }, [loading])

    const triggerAction = () => {
        setLoading(true)
    }

    return (
        <>
            {error ? (
                <span className="text-danger">An Error Occurred</span>
            ) : (
                loading ? (
                    <span>Loading...</span>
                ) : (
                    <button
                        onClick={triggerAction}
                        className="btn btn-sm btn-ocean-blue">{props.children}</button>
                )
            )}
        </>
    )
}
