import React, {useCallback, useEffect, useState} from 'react'
import {Page} from "@electrix/shared";
import UpdatePreferences from "./UpdatePreferences";


export const MarketingFormPreview = () => {
    const [page, setPage] = useState<Page>()

    const handleMessage = useCallback((event : MessageEvent<any>) => {
        if (event.data.type === 'preview') {
            setPage(event.data.value);
        }
    }, [setPage]);

    useEffect(() => {
        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [handleMessage]);


    return page ? <UpdatePreferences
        page="preview"
        preview={true}
        pageConfig={page}
        marketingChannel="PREVIEW"
        onPreferenceChange={() => {}}/> : <></>

}
