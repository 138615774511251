export const NavBar = () => {

    return (
        <>
            <nav id="header" className="navbar navbar-expand-lg navbar-dark bg-primary-3 banner">
                <div className="container">
                    <a className="navbar-brand" href="https://www.lvelectrix.co.uk/" title="ElectriX"
                       rel="home">
                        <img src='/account-img/electrix-white-logo.svg' className="py-2 banner__logo"
                             alt="ElectriX Logo"/>
                    </a>
                    <div className="d-flex">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>

                    <div id="navbar" className="collapse navbar-collapse">
                        <ul id="menu-header" className="navbar-nav w-100 justify-content-center">
                            <li className="nav-item">
                                <a title="Personal" href="https://www.lvelectrix.co.uk/personal"
                                   className="nav-link">Personal</a></li>
                            <li className="nav-item">
                                <a title="Business" href="https://www.lvelectrix.co.uk/business"
                                   className="nav-link">Business</a></li>
                            <li className="nav-item">
                                <a title="Salary Sacrifice"
                                   href="https://www.lvelectrix.co.uk/salary-sacrifice"
                                   className="nav-link">Salary Sacrifice</a></li>
                            <li className="nav-item">
                                <a aria-current="page" title="Discover Cars"
                                   href="https://portal.lvelectrix.co.uk/vehicle-gallery"
                                   className="nav-link active">Discover Cars</a></li>
                            <li className="nav-item">
                                <a title="Learn" href="https://www.lvelectrix.co.uk/learn"
                                   className="nav-link">Learn</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default NavBar;