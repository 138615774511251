import ReactHtmlParser from 'react-html-parser';
import React from "react";
import {get, escape} from "lodash";

interface IProps {
    html: string
    context?: any
}

export const Html = (props: IProps)  => {

    const content = props.html.replace(/\${([^}]+)\}/g, (matched: string): string => {
        const field: string = matched.substring(2, matched.length-1);
        const value: string = get(props.context, field);
        return escape(value);
    });

    return <>
        {ReactHtmlParser(content, {
            transform(node) {
                if (node.name === 'script') {
                    return null;
                }
                return;
            }
        })}
    </>

}

