import {Vehicle} from "../model/Vehicle";
import {get} from "lodash";

export function notEmpty({parameter}: { parameter: any }) {
    return parameter !== null && parameter !== undefined ? parameter : ""
}

export const throwErrorOnNotOk = (response: Response) => {
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`
        throw new Error(message)
    }
}
export const getQueryParam = (name: string) => {
    const { search } = window.location;

    const params:any = new Proxy(new URLSearchParams(search), {
        get: (searchParams, prop) => searchParams.get(prop.toString()),
    });

    return params[name];
}

export function getProperty(prop: string) : string | undefined {
    return get(window, `_env_.${prop}`)
}

export const getVehicleImageSrc = (vehicle: Vehicle) => {
    const angles = ["28", "23", "1",]
    const colors = ["white", "solid-black", "blue"]

    const getParamValue = (id: number, available: string[], index?: number): string => {

        index ??= available.length - 1
        const remainder = id % (index + 1);
        if (remainder === 1) {
            return getParamValue(id, available, --index)
        } else {
            return available[index]
        }
    }
    const params = new URLSearchParams({

        // Identification
        customer: getProperty("imaginCustomer")??"lvelectrix",

        // Car
        make: vehicle.manufacturer,
        modelFamily: vehicle.range,
        modelRange: vehicle.model,
        modelYear: vehicle.modelYear,
        bodySize: vehicle.doors + "",

        // Customization
        angle: getParamValue(vehicle.variantId, angles),
        paintDescription: getParamValue(vehicle.variantId, colors),
        width: "300",
        zoomLevel: "70",
        groundPlaneAdjustment: "-0.3",
        steering: "rhd"
    });


    return `https://cdn.imagin.studio/getImage?${params.toString()}`;
}

