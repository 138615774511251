import React from 'react';

interface IProps {
    numberOfPages: number
    currentPage: number
    onChange: (page: number) => void
}

export const Paginator = (props: IProps) => {

    const pagesBeforeCurrent = Array.from(Array(props.currentPage).keys()).slice(-3)
    const pagesAfterCurrent = Array.from(Array(props.numberOfPages).keys()).slice(props.currentPage+1, props.currentPage+4)

    return (
        <nav>
            <ul className="pagination d-inline-flex">
                <li className={`page-item ${props.currentPage === 0 && "disabled"}`}>
                    <a href="#" onClick={() => props.onChange(props.currentPage-1)} className="page-link">
                        <span aria-hidden="true">&lang;</span>
                    </a>
                </li>
                {pagesBeforeCurrent.map(page=> {
                    return <li  key={page} className="page-item">
                        <a href="#" onClick={() => props.onChange(page)} className="page-link" >{page + 1}</a>
                    </li>
                })}
                <li className="page-item active">
                    <a href="#" className="page-link" >{props.currentPage + 1}</a>
                </li>
                {pagesAfterCurrent.map(page => {
                    return <li  key={page} className="page-item">
                        <a href="#" onClick={() => props.onChange(page)} className="page-link" >{page + 1}</a>
                    </li>
                })}
                <li className={`page-item ${(props.numberOfPages === 0 || props.currentPage === (props.numberOfPages-1)) && "disabled"}`}>
                    <a href="#" onClick={() => props.onChange(props.currentPage+1)} className="page-link">
                        <span aria-hidden="true">&rang;</span>
                    </a>
                </li>
            </ul>
            <div className="d-inline-block ms-4">
                Page {props.currentPage + 1} of {props.numberOfPages}
            </div>

        </nav>
    )
}

export default Paginator;
