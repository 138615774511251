import React, {useCallback, useEffect, useState} from 'react'
import {Page} from "@electrix/shared";
import Registration from "./Registration";


export const SignOffPreview = () => {

  const [page, setPage] = useState<Page>()

  const handleMessage = useCallback((event : MessageEvent<any>) => {
    if (event.data.type === 'preview') {
      setPage(event.data.value);
    }
  }, [setPage]);

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);

  return page ? <Registration pageConfig={page} marketingChannel="PREVIEW"/> : <></>
}
