import {plainToInstance} from "class-transformer";
import {ProblemDetail} from "./model/ProblemDetail";

export const throwErrorOnNotOk = (response: Response) => {
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`
        throw new Error(message)
    }
}

export const rest = async <Req, Res>(url: string, method?: string, request?: Req, noResponseBody?: boolean, headers?: HeadersInit): Promise<Res> => {
    const response = await fetch(url, {
        headers: {...headers, 'Content-Type': 'application/json'},
        method: method,
        body: request ? JSON.stringify(request) : undefined
    })

    return handleResponse(response, noResponseBody);
}

export const postWithFile = async <Res>(url: string, file: File, noResponseBody?: boolean, headers?: HeadersInit): Promise<Res> => {
    const formData = new FormData()
    formData.append('file', file)
    const response = await fetch(url,
        {
            headers: {...headers},
            method: 'POST',
            body: formData
        })

    return handleResponse(response, noResponseBody);
}

export const handleResponse = async <Res> (response: Response, noResponseBody?: boolean) => {
    let json;
    try {

        if (response.ok) {
            if (noResponseBody) {
                return {} as Res
            }

            return await response.json();
        }
        json = await response.json();
    } catch (e) {
        const pd = new ProblemDetail();
        pd.status = response.status;
        pd.detail = "Did not receive JSON from API server, instead received:" + response.status + " " + response.statusText;
        return Promise.reject(pd);
    }

    const problemDetail = plainToInstance(ProblemDetail, json);

    if (!problemDetail?.detail) {
        const pd = new ProblemDetail();
        pd.status = response.status;
        pd.detail = response.statusText;
        return Promise.reject(pd);
    }

    return Promise.reject(problemDetail);
}
