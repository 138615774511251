import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {fetchOffer, fetchOffers} from "../services/OfferService";
import {RootState} from "../store/Store";

import {
  fulfilledCaseReducer,
  getActinStatePrefix,
  pendingCaseReducer,
  rejectedCaseReducer,
  StateMandatory,
  ThunkStatus
} from "../store/State";
import {LinkedOffers} from "../model/LinkedOffers";
import {fetchLeasingLink} from "../services/LeasingLinkService";

const FETCH_OFFERS_TYPE_PREFIX: string = 'posts/fetchOffers';
const FETCH_OFFER_TYPE_PREFIX: string = 'posts/fetchOffer';
const FETCH_LEASING_LINK_TYPE_PREFIX: string = 'posts/fetchLeasingLink';

export const fetchOffersThunk = createAsyncThunk(FETCH_OFFERS_TYPE_PREFIX, async () => {
  return fetchOffers();
})

export const fetchOfferThunk = createAsyncThunk(FETCH_OFFER_TYPE_PREFIX, async (linkedOfferId: number) => {
  return fetchOffer(linkedOfferId);
})


export const fetchLeasingLinkThunk = createAsyncThunk(FETCH_LEASING_LINK_TYPE_PREFIX, async (vehicleVariantId: number) => {
  return fetchLeasingLink(vehicleVariantId)
})

const initialState: StateMandatory<LinkedOffers> = {
  data: {
    mileage: 0,
    term: 0,
    homeChargerCost: 0,
    standardInstallationCost: 0,
    carTax: 0,
    currentElectricityPrice: 0,
    currentPetrolPrice: 0,
    currentDieselPrice: 0,
    offers: []
  },
  status: {},
  error: undefined
}

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchOffersThunk.pending, pendingCaseReducer)
      .addCase(fetchOffersThunk.fulfilled, fulfilledCaseReducer)
      .addCase(fetchOffersThunk.rejected, rejectedCaseReducer)

      .addCase(fetchOfferThunk.pending, pendingCaseReducer)
      .addCase(fetchOfferThunk.fulfilled, fulfilledCaseReducer)
      .addCase(fetchOfferThunk.rejected, rejectedCaseReducer)

      .addCase(fetchLeasingLinkThunk.pending, pendingCaseReducer)
      .addCase(fetchLeasingLinkThunk.fulfilled, ((state, action) => {
        let suffix = action.meta.arg ? "/" + action.meta.arg : ""
        state.status[getActinStatePrefix(action) + suffix] = ThunkStatus.succeeded

        const offer = state.data.offers.find(item => item.evOffer.vehicle.variantId === action.meta.arg)
        if (offer) {
          offer.evOffer.vehicle.leasingLink = action.payload.leasingLink
        }
      }))
      .addCase(fetchLeasingLinkThunk.rejected, rejectedCaseReducer)
  }
})

export default offersSlice.reducer
export const selectAllOffers = (state: RootState) => state.offers.data
export const selectOfferByLinkedOfferId = (state: RootState, linkedOfferId?: number) => state.offers.data.offers.find(item => item.id === linkedOfferId)

export const selectFetchOffersStatus = (state: RootState) => {
  const status = state.offers.status[FETCH_OFFERS_TYPE_PREFIX]
  return status ? status : ThunkStatus.idle
}

export const selectFetchOfferStatus = (state: RootState, linkedOfferId: number) => {
  const status = state.offers.status[FETCH_OFFER_TYPE_PREFIX + "/" + linkedOfferId]
  return status ? status : ThunkStatus.idle
}

export const selectFetchLeasingLinkStatus = (state: RootState, linkedOfferId: number) => {
  const offer = selectOfferByLinkedOfferId(state, linkedOfferId)
  if (offer) {
    const status = state.offers.status[FETCH_LEASING_LINK_TYPE_PREFIX + "/" + offer.evOffer.vehicle.variantId]
    return status ? status : ThunkStatus.idle
  }
  return ThunkStatus.idle;
}
