import React, {FormEvent, useEffect, useState} from 'react';
import {UnsubscriptionReason} from '../../model/Unsubscription';
import {fetchSubscription, unsubscribe} from '../../services/SubscriptionService';
import Footer from "../Footer";
import {useParams} from "react-router-dom";

export const Unsubscribe = () => {

  const [unsubscribed, setUnsubscribed] = useState(false)
  const [alreadyUnsubscribed, setAlreadyUnsubscribed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const identity = useParams().identity as string
  useEffect(() => {
    fetchSubscription(
      identity
    ).then(s => {
      if (s.contactPreference === 'NONE') setAlreadyUnsubscribed(true)
    })
  }, [identity]);

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsDisabled(true)

    unsubscribe({
      comment: '',
      reason: UnsubscriptionReason.I_DONT_WANT_TO_GIVE_A_REASON,
    }).then(e => {
      setUnsubscribed(true)
      setIsDisabled(false)
    })
  }

  return (
    <>
      {!alreadyUnsubscribed ? (
        <>
          <header className='header-theme'>
            <div className='unsubscription-header container-lg pb-5'>
              <img alt='Logo' className='header__logo-image pt-1 pt-lg-5 pb-lg-4' src='/account-img/electrix-white-logo.svg'/>
              <div className='unsubscription-header__container py-4'>
                <h1 className='text-start'>
                  How to unsubscribe<br/>
                  from our emails
                </h1>
              </div>

              <div className='unsubscription-container d-lg-none'>
                <div className='unsubscription-box col-12'>
                  {!unsubscribed ? (
                    <form className='unsubscription-box__form bg-white p-4 shadow-sm' onSubmit={onSubmit}>
                      <p>
                        Click the ‘Unsubscribe’ button below to unsubscribe from future ElectriX marketing emails.
                      </p>
                      <div className='input-container'>
                        <input className='cta cta--blue' type='submit' value='Unsubscribe' name='unsubscribe'
                               disabled={isDisabled}/>
                      </div>
                    </form>
                  ) : (
                    <div className='unsubscription-box__form bg-white px-4 py-5 shadow-sm'>
                      <div className='d-flex align-items-center mb-5'>
                        <img src='/account-img/check.png' className='me-3' alt='Check'/>
                        <h2 className="qa-unsubscription-success-mobile">You've unsubscribed</h2>
                      </div>
                      <p>Thanks for travelling on the journey so far.</p>
                      <p>You can continue to keep in touch on social media.</p>
                      <div>
                        <a href='https://www.facebook.com/LVElectriX'><img src='/account-img/facebook.svg' className='me-4'
                                                                           alt='Facebook'/></a>
                        <a href='https://www.linkedin.com/company/lvelectrix'><img src='/account-img/linkedin.svg'
                                                                                   className='me-4' width='25'
                                                                                   alt='Linkedin'/></a>
                        <a href='https://twitter.com/LVElectriX'><img src='/account-img/twitter.svg' className='me-4'
                                                                      alt='Twitter'/></a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </header>

          <section className='d-none d-lg-block bg-light'>
            <div className='container-lg'>
              <div className='unsubscription-container'>
                <div className='unsubscription-box col-7 col-xl-6 col-xxl-5'>
                  {!unsubscribed ? (
                    <form className='unsubscription-box__form bg-white p-4 shadow-sm' onSubmit={onSubmit}>
                      <p className='mb-4'>
                        Click the ‘Unsubscribe’ button below to unsubscribe from future ElectriX marketing emails.
                      </p>
                      <div className='input-container pb-5 mb-5'>
                        <input className='cta cta--blue mt-1' type='submit' value='Unsubscribe' name='unsubscribe-desktop'
                               disabled={isDisabled}/>
                      </div>
                    </form>
                  ) : (
                    <div className='unsubscription-box__form bg-white p-4 shadow-sm'>
                      <div className='d-flex align-items-center mt-3 mb-4'>
                        <img src='/account-img/check.png' className='me-3' alt='Check'/>
                        <h2 className="qa-unsubscription-success-desktop">You've unsubscribed</h2>
                      </div>
                      <p>Thanks for travelling on the journey so far.</p>
                      <p>You can continue to keep in touch on social media.</p>
                      <div className='mb-3'>
                        <a href='https://www.facebook.com/LVElectriX'><img src='/account-img/facebook.svg' className='me-4'
                                                                           alt='Facebook'/></a>
                        <a href='https://www.linkedin.com/company/lvelectrix'><img src='/account-img/linkedin.svg'
                                                                                   className='me-4' width='25'
                                                                                   alt='Linkedin'/></a>
                        <a href='https://twitter.com/LVElectriX'><img src='/account-img/twitter.svg' className='me-4'
                                                                      alt='Twitter'/></a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <header className='header-theme'>
            <div className='unsubscription-header container-lg pb-5'>
              <img alt='Logo' className='header__logo-image pt-1 pt-lg-5 pb-lg-4' src='/account-img/electrix-white-logo.svg'/>
              <div className='unsubscription-header__container--wide py-4'>
              </div>

              <div className='unsubscription-container d-lg-none'>
                <div className='unsubscription-box col-12'>
                  <div className='unsubscription-box__form bg-white px-4 py-5 shadow-sm'>
                    <div className='d-flex align-items-center mb-5'>
                      <img src='/account-img/check.png' className='me-3' alt='Check'/>
                      <h2 className="qa-unsubscription-already-success-mobile">You've already unsubscribed</h2>
                    </div>
                    <p>Thanks for travelling on the journey so far.</p>
                    <p>You can continue to keep in touch on social media.</p>
                    <div>
                      <a href='https://www.facebook.com/LVElectriX'><img src='/account-img/facebook.svg' className='me-4'
                                                                         alt='Facebook'/></a>
                      <a href='https://www.linkedin.com/company/lvelectrix'><img src='/account-img/linkedin.svg'
                                                                                 className='me-4' width='25'
                                                                                 alt='Linkedin'/></a>
                      <a href='https://twitter.com/LVElectriX'><img src='/account-img/twitter.svg' className='me-4'
                                                                    alt='Twitter'/></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className='d-none d-lg-block bg-light'>
            <div className='container-lg'>
              <div className='unsubscription-container'>
                <div className='unsubscription-box col-7 col-xxl-5'>
                  <div className='unsubscription-box__form unsubscription-box__form--wide bg-white p-4 shadow-sm'>
                    <div className='d-flex align-items-center mt-3 mb-4'>
                      <img src='/account-img/check.png' className='me-3' alt='Check'/>
                      <h2 className="qa-unsubscription-already-success-desktop">You've already unsubscribed</h2>
                    </div>
                    <p>Thanks for travelling on the journey so far.</p>
                    <p>You can continue to keep in touch on social media.</p>
                    <div className='mb-3'>
                      <a href='https://www.facebook.com/LVElectriX'><img src='/account-img/facebook.svg' className='me-4'
                                                                         alt='Facebook'/></a>
                      <a href='https://www.linkedin.com/company/lvelectrix'><img src='/account-img/linkedin.svg'
                                                                                 className='me-4' width='25'
                                                                                 alt='Linkedin'/></a>
                      <a href='https://twitter.com/LVElectriX'><img src='/account-img/twitter.svg' className='me-4'
                                                                    alt='Twitter'/></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <Footer/>
    </>
  )
}

export default Unsubscribe;
